import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const EditIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-original",
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_315_2736)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0842 30.1017C17.0842 29.3229 17.7157 28.6914 18.4946 28.6914H30.4862C31.2651 28.6914 31.8965 29.3229 31.8965 30.1017C31.8965 30.8806 31.2651 31.5121 30.4862 31.5121H18.4946C17.7157 31.5121 17.0842 30.8806 17.0842 30.1017Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4793 2.85C16.8424 -0.2984 21.3102 -0.935702 24.4605 1.4269C27.6093 3.79032 28.2444 8.26083 25.8818 11.4087L24.7539 10.5621L23.6258 9.71553C25.0542 7.81253 24.6696 5.11068 22.7676 3.68314C20.8632 2.2551 18.1631 2.64084 16.7353 4.5432L4.03863 21.4576C2.7281 23.2046 2.6592 25.1877 2.98076 26.8592C3.14056 27.6898 3.39166 28.4074 3.60368 28.9172C3.6301 28.9808 3.65581 29.0408 3.68052 29.0972C3.74383 29.1049 3.81121 29.1126 3.88236 29.1199C4.43674 29.1773 5.20287 29.2134 6.05097 29.1314C7.76211 28.9659 9.64438 28.3427 10.9288 26.6306C12.5411 24.4831 15.7157 20.254 18.4869 16.5623L23.6258 9.71553L24.7539 10.5621L25.8818 11.4087L20.7427 18.2554C17.9717 21.9473 14.7975 26.1761 13.1848 28.3236C11.2461 30.9077 8.467 31.7315 6.32242 31.9389C5.24259 32.0433 4.28222 31.997 3.5921 31.9257C3.24542 31.8898 2.96252 31.8471 2.76156 31.8123C2.66095 31.7949 2.58055 31.7794 2.52255 31.7676L2.45256 31.7529L2.43062 31.7482L2.423 31.7465L2.42003 31.7458C2.41947 31.7456 2.41764 31.7452 2.73373 30.3708C1.50991 31.0718 1.50963 31.0713 1.50933 31.0707L1.50715 31.0669L1.50325 31.06L1.4922 31.0402L1.45784 30.9774C1.42977 30.9252 1.39172 30.8528 1.34607 30.7616C1.2549 30.5795 1.13281 30.3215 0.999274 30.0003C0.733491 29.3614 0.415619 28.4563 0.210876 27.392C-0.19673 25.2735 -0.177249 22.3768 1.78233 19.7649L14.4793 2.85ZM2.73373 30.3708L1.50933 31.0707C1.70478 31.412 2.03441 31.6572 2.41764 31.7452L2.73373 30.3708Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0432 6.13225C12.5108 5.50931 13.3949 5.38338 14.0178 5.85097L23.1643 12.7165C23.7873 13.184 23.9132 14.0682 23.4456 14.691C22.9781 15.314 22.0939 15.44 21.4711 14.9723L12.3246 8.10683C11.7016 7.63924 11.5756 6.75518 12.0432 6.13225Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_315_2736">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
