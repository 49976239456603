import React from "react";
import { FormTask } from "../../components/MainPage/FormTask";
import { Header } from "../../components/Header/Header";
import { MessengerChat } from "../../components/MessengerChat/MessengerChat";

export const MainPage = () => {
  return (
    <>
      <Header />
      <FormTask />
      <MessengerChat />
    </>
  );
};
