export enum RegistrationPagesEnums {
  subscriptionsOptions = "SUBSCRIPTION_OPTIONS",
  payment = "PAYMENT",
}

export enum SubscriptionsOptionsEnums {
  free = "FREE",
  basic = "BASIC",
  pro = "PRO",
}
