import { useState, useEffect, FunctionComponent } from "react";

interface Props {
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  body: HTMLBodyElement | null;
  backgroundColor: string;
  content: string;
  icon: JSX.Element;
}

const Notification: FunctionComponent<Props> = ({
  setShowNotification,
  body,
  backgroundColor,
  content,
  icon,
}) => {
  const [isTimeToHide, setIsTimeToHide] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsTimeToHide(true);
    }, 3000);
    setTimeout(() => {
      setShowNotification(false);
      if (body) body.style.overflow = "auto";
    }, 3400);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`flex items-center justify-center gap-3 py-2 px-3 absolute -bottom-[10px] bg-white border border-black-70 shadow-notification rounded-md ${
        isTimeToHide ? "animate-hideNotification" : "animate-showNotification"
      } `}
    >
      <div
        className={`w-7 h-7 rounded-md relative ${backgroundColor}`}
      >
        <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
          {icon}
        </div>
      </div>
      <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
        {content}
      </p>
    </div>
  );
};

export default Notification;
