import { useState } from "react";
import { LogoIcon } from "../../components/Icons/Logo";
import { ResetPasswordCard } from "../../components/ResetPasswordCard/ResetPasswordInput";
import { ResetPassInfoAfterSended } from "../../components/ResetPassInfoAfterSended/ResetPassInfoAfterSended";
import { ResetPasswordPages } from "../../constants/ResetPasswordPages";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [isEmailCorrect, setIsEmailCorrect] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    ResetPasswordPages.pageBeforeSendedLink
  );
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const emailValidation = () => {
    if (email.match(emailRegex)) {
      setIsEmailCorrect(true);
      setCurrentPage(ResetPasswordPages.pageAfterSendedLink);
    } else {
      setIsEmailCorrect(false);
    }
  };

  const handleInCorrectTypedEmail = () => {
    setIsEmailCorrect(true);
    setCurrentPage(ResetPasswordPages.pageBeforeSendedLink);
  };

  const pages = [
    {
      name: ResetPasswordPages.pageBeforeSendedLink,
      component: (
        <ResetPasswordCard
          email={email}
          handleEmail={handleEmail}
          emailValidation={emailValidation}
          isEmailCorrect={isEmailCorrect}
        />
      ),
    },
    {
      name: ResetPasswordPages.pageAfterSendedLink,
      component: (
        <ResetPassInfoAfterSended
          email={email}
          handleInCorrectTypedEmail={handleInCorrectTypedEmail}
        />
      ),
    },
  ];

  return (
    <div className="flex h-screen items-center justify-center gap-20">
      <div className="fixed top-[18px] left-4 w-[180px] h-8 bg-button-default"></div>
      <LogoIcon />
      {pages.find((el) => el.name === currentPage)?.component}
    </div>
  );
};
