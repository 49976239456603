import { HeaderLogoIcon } from "../Icons/HeaderLogo";
import style from "../../styles/App.module.css";
import { clearToken } from "../../helpers/auth";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

export const Header = () => {
  const navigate = useNavigate();

  const logout = () => {
    clearToken();
    navigate(ROUTES.login);
  };

  return (
    <header
      className={`border-b border-slate-50 flex justify-center ${style.borderColorGradient}`}
    >
      <div className="max-w-desktop w-full mx-4 flex items-center justify-between py-4 xl:mx-0">
        <HeaderLogoIcon />
        <button
          onClick={logout}
          className="min-h-[48px] min-w-[130px] py-3 px-5 rounded-md border border-ctaBorder font-inter font-semibold text-[16px] leading-md text-black-75"
        >
          Wyloguj
        </button>
      </div>
    </header>
  );
};
