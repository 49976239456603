import { LogoIcon } from "../Icons/Logo";

export const InfoMobile = () => {
  return (
    <div className="w-screen h-screen bg-white flex items-center justify-center flex-col">
      <LogoIcon />
      <h4 className="font-satoshi font-black text-1xl leading-1xl tracking-md text-center text-black-original mt-12 max-w-[320px]">
        Odwiedź nas na komputerze
      </h4>
      <p className="font-satoshi font-normal text-md leading-md tracking-sm text-center text-black-original mt-3 max-w-[320px]">
        Lorem ipsum dolor sit amet consectetur. Eu est aenean tincidunt feugiat
        eu egestas. Nibh nisl tortor vestibulum ultrices accumsan libero
        fermentum. Feugiat ac suspendisse feugiat mauris. Magna sollicitudin
        amet non at sed augue amet penatibus.
      </p>
    </div>
  );
};
