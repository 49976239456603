export const HeaderLogoIcon = () => {
  return (
    <svg
      width="232"
      height="48"
      viewBox="0 0 232 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20_393)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5938 5.90625H10.4062C7.92097 5.90625 5.90625 7.92097 5.90625 10.4062V37.5938C5.90625 40.0793 7.92097 42.0938 10.4062 42.0938H37.5938C40.0793 42.0938 42.0938 40.0793 42.0938 37.5938V10.4062C42.0938 7.92097 40.0793 5.90625 37.5938 5.90625ZM10.4062 5.71875C7.8174 5.71875 5.71875 7.8174 5.71875 10.4062V37.5938C5.71875 40.1828 7.8174 42.2812 10.4062 42.2812H37.5938C40.1828 42.2812 42.2812 40.1828 42.2812 37.5938V10.4062C42.2812 7.8174 40.1828 5.71875 37.5938 5.71875H10.4062Z"
          fill="#C780FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 47.8125C37.1512 47.8125 47.8125 37.1512 47.8125 24C47.8125 10.8488 37.1512 0.1875 24 0.1875C10.8488 0.1875 0.1875 10.8488 0.1875 24C0.1875 37.1512 10.8488 47.8125 24 47.8125ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="#A12AFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5937 1.50001L41.7187 6.375L41.5859 6.24244L46.4609 1.36743L46.5937 1.50001Z"
          fill="#CDCDCD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.8141 8.27933L25.3767 22.7169L25.2441 22.5842L39.6817 8.14673L39.8141 8.27933Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2986 24.0663H25.8809V23.8788H46.2986V24.0663Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5 46.5937L41.625 41.7187L41.7577 41.586L46.6327 46.461L46.5 46.5937Z"
          fill="#CDCDCD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.7203 39.8141L25.283 25.3768L25.4156 25.2442L39.8531 39.6817L39.7203 39.8141Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9336 46.2986V25.8809H24.1211V46.2986H23.9336Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.40625 46.5L6.28125 41.625L6.41381 41.7577L1.53883 46.6327L1.40625 46.5Z"
          fill="#CDCDCD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.18555 39.7204L22.623 25.283L22.7556 25.4157L8.31811 39.8531L8.18555 39.7204Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.70142 23.9337H22.1192V24.1212H1.70142V23.9337Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.3125 0.1875H4.6875C2.20221 0.1875 0.1875 2.20221 0.1875 4.6875V43.3125C0.1875 45.798 2.20221 47.8125 4.6875 47.8125H43.3125C45.798 47.8125 47.8125 45.798 47.8125 43.3125V4.6875C47.8125 2.20221 45.798 0.1875 43.3125 0.1875ZM4.6875 0C2.09867 0 0 2.09867 0 4.6875V43.3125C0 45.9015 2.09867 48 4.6875 48H43.3125C45.9015 48 48 45.9015 48 43.3125V4.6875C48 2.09867 45.9015 0 43.3125 0H4.6875Z"
          fill="#9B9B9B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.50001 1.40625L6.375 6.28125L6.24244 6.41381L1.36743 1.53883L1.50001 1.40625Z"
          fill="#CDCDCD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.27929 8.18573L22.7168 22.6233L22.5843 22.7558L8.14673 8.31833L8.27929 8.18573Z"
          fill="#7200CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0664 1.70142V22.1192H23.8789V1.70142H24.0664Z"
          fill="#7200CC"
        />
        <path
          d="M74.6966 14.1113V24.4247L66.2516 14.1113H63V31.098H66.8828V20.7847L75.3518 31.098H78.5794V14.1113H74.6966Z"
          fill="#353535"
        />
        <path
          d="M87.5973 17.8481C85.5104 17.8481 83.3992 18.4062 81.9674 19.4254L83.3264 22.0705C84.2726 21.3183 85.7043 20.8572 87.0877 20.8572C89.1262 20.8572 90.0967 21.8036 90.0967 23.4294H87.0877C83.1078 23.4294 81.4822 25.031 81.4822 27.3364C81.4822 29.5932 83.3021 31.2918 86.3594 31.2918C88.2768 31.2918 89.6354 30.6609 90.3393 29.4718V31.0977H93.8823V23.6479C93.8823 19.6924 91.5768 17.8481 87.5973 17.8481ZM87.3059 28.7439C85.9713 28.7439 85.1707 28.1129 85.1707 27.1665C85.1707 26.2929 85.7287 25.6377 87.5002 25.6377H90.0967V26.9724C89.6598 28.1614 88.5678 28.7439 87.3059 28.7439Z"
          fill="#353535"
        />
        <path
          d="M105.025 17.8481C103.278 17.8481 101.871 18.4062 100.924 19.5468V18.0422H97.3083V35.8054H101.094V29.7145C102.065 30.7823 103.399 31.2918 105.025 31.2918C108.69 31.2918 111.529 28.6711 111.529 24.57C111.529 20.4689 108.69 17.8481 105.025 17.8481ZM104.37 28.1857C102.477 28.1857 101.046 26.8268 101.046 24.57C101.046 22.3132 102.477 20.9542 104.37 20.9542C106.263 20.9542 107.695 22.3132 107.695 24.57C107.695 26.8268 106.263 28.1857 104.37 28.1857Z"
          fill="#353535"
        />
        <path
          d="M115.956 16.2224C117.363 16.2224 118.31 15.3003 118.31 14.0384C118.31 12.8736 117.363 12 115.956 12C114.549 12 113.602 12.9221 113.602 14.1112C113.602 15.3003 114.549 16.2224 115.956 16.2224ZM114.063 31.0979H117.848V18.0424H114.063V31.0979Z"
          fill="#353535"
        />
        <path
          d="M125.754 31.2918C129.661 31.2918 131.918 29.5689 131.918 27.1422C131.918 21.9977 124.031 24.1574 124.031 21.9249C124.031 21.2454 124.759 20.7116 126.458 20.7116C127.647 20.7116 128.933 20.9542 130.219 21.6822L131.481 18.9886C130.244 18.2606 128.254 17.8481 126.458 17.8481C122.648 17.8481 120.416 19.5953 120.416 22.0705C120.416 27.2878 128.302 25.1038 128.302 27.2393C128.302 27.9673 127.647 28.4284 125.9 28.4284C124.299 28.4284 122.527 27.9187 121.362 27.1665L120.1 29.8844C121.314 30.6852 123.546 31.2918 125.754 31.2918Z"
          fill="#353535"
        />
        <path
          d="M138.136 28.1861L144.786 20.3237V18.0426H133.526V20.9546H140.005L133.356 28.817V31.0981H145.004V28.1861H138.136Z"
          fill="#353535"
        />
        <path
          d="M160.052 24.6185C160.052 20.4204 157.092 17.8481 153.258 17.8481C149.278 17.8481 146.293 20.663 146.293 24.57C146.293 28.4527 149.229 31.2918 153.743 31.2918C156.097 31.2918 157.917 30.5638 159.13 29.1806L157.116 26.9966C156.218 27.846 155.223 28.2585 153.84 28.2585C151.85 28.2585 150.467 27.2636 150.103 25.6377H159.98C160.004 25.3222 160.052 24.9097 160.052 24.6185ZM153.282 20.7116C154.981 20.7116 156.218 21.7793 156.485 23.4294H150.054C150.321 21.755 151.559 20.7116 153.282 20.7116Z"
          fill="#353535"
        />
        <path
          d="M179.292 17.8481C177.326 17.8481 175.628 18.6489 174.584 19.9836C173.662 18.5518 172.109 17.8481 170.289 17.8481C168.615 17.8481 167.159 18.4305 166.188 19.5468V18.0422H162.572V31.0977H166.358V24.5457C166.358 22.2161 167.547 21.1241 169.221 21.1241C170.799 21.1241 171.696 22.0948 171.696 24.2059V31.0977H175.482V24.5457C175.482 22.2161 176.671 21.1241 178.37 21.1241C179.899 21.1241 180.845 22.0948 180.845 24.2059V31.0977H184.631V23.6236C184.631 19.6438 182.398 17.8481 179.292 17.8481Z"
          fill="#353535"
        />
        <path
          d="M197.105 18.0426L193.417 26.8999L189.753 18.0426H185.845L191.5 31.1951L191.451 31.3165C190.941 32.4813 190.359 32.9423 189.316 32.9423C188.563 32.9423 187.762 32.6268 187.18 32.1173L185.797 34.8109C186.646 35.5631 188.102 35.9999 189.437 35.9999C191.791 35.9999 193.587 35.0535 194.848 31.8989L200.745 18.0426H197.105Z"
          fill="#353535"
        />
        <path
          d="M202.94 31.292C204.25 31.292 205.294 30.2971 205.294 28.9139C205.294 27.5064 204.25 26.5843 202.94 26.5843C201.63 26.5843 200.586 27.5064 200.586 28.9139C200.586 30.2971 201.63 31.292 202.94 31.292Z"
          fill="#353535"
        />
        <path
          d="M215.166 27.7978C214.753 28.1132 214.195 28.2831 213.637 28.2831C212.618 28.2831 212.011 27.6764 212.011 26.5601V21.2457H215.263V18.3337H212.011V15.1548H208.226V18.3337H206.211V21.2457H208.226V26.6087C208.226 29.7148 210.021 31.2921 213.103 31.2921C214.268 31.2921 215.409 31.0252 216.185 30.4671L215.166 27.7978Z"
          fill="#353535"
        />
        <path
          d="M224.119 31.2918C228.269 31.2918 231.278 28.5012 231.278 24.57C231.278 20.6388 228.269 17.8481 224.119 17.8481C219.97 17.8481 216.936 20.6388 216.936 24.57C216.936 28.5012 219.97 31.2918 224.119 31.2918ZM224.119 28.1857C222.226 28.1857 220.771 26.8268 220.771 24.57C220.771 22.3132 222.226 20.9542 224.119 20.9542C226.012 20.9542 227.444 22.3132 227.444 24.57C227.444 26.8268 226.012 28.1857 224.119 28.1857Z"
          fill="#353535"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_393">
          <rect width="231.375" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
