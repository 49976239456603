import React, { ReactElement, useEffect } from "react";
import { getToken } from "../../helpers/auth";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

interface Props {
  children: ReactElement;
}

export const AuthorizedOnly: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) {
      navigate(ROUTES.welcome);
    }
  }, [navigate]);

  return (
    <>
      {children}
    </>
  );
};
