export const PlusIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_429_3064)">
        <path
          d="M10 0C4.47342 0 0 4.47267 0 10C0 15.5266 4.47263 20 10 20C15.5266 20 20 15.5274 20 10C20 4.47342 15.5273 0 10 0ZM10 18.3333C5.405 18.3333 1.66667 14.595 1.66667 10C1.66667 5.405 5.405 1.66667 10 1.66667C14.595 1.66667 18.3333 5.405 18.3333 10C18.3333 14.595 14.595 18.3333 10 18.3333Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4.66667C10.3682 4.66667 10.6667 4.96514 10.6667 5.33333V14.6667C10.6667 15.0349 10.3682 15.3333 10 15.3333C9.63181 15.3333 9.33333 15.0349 9.33333 14.6667V5.33333C9.33333 4.96514 9.63181 4.66667 10 4.66667Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66667 10C4.66667 9.63181 4.96514 9.33333 5.33333 9.33333H14.6667C15.0349 9.33333 15.3333 9.63181 15.3333 10C15.3333 10.3682 15.0349 10.6667 14.6667 10.6667H5.33333C4.96514 10.6667 4.66667 10.3682 4.66667 10Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_3064">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
