import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useFormik } from "formik";
import { CardInterface, PropsType } from "../../types/RegisterCardSubOptions";
import { cardSchema } from "../../validation/registerSchema";
import { InfoIcon } from "../Icons/Info";
import { Input } from "../Input/Input";
import { ArrowLeftIcon } from "../Icons/ArrowLeft";
import {
  RegistrationPagesEnums,
  SubscriptionsOptionsEnums,
} from "../../constants/PopupWithoutPlanEnums";
import {
  mockedArraySubOptions,
  SubscriptionDetailsType,
} from "../SubscriptionsOptions/SubscriptionsOptions";
import { SubscriptionsOption } from "../SubscriptionsOptions/SubscriptionsOption/SubscriptionsOption";

interface Props extends PropsType {
  subscriptionsOption: SubscriptionsOptionsEnums;
}

export const AddCardPayment: FunctionComponent<Props> = ({
  onChangePage,
  setRegisterState,
  setIsShowModalWithSub,
  subscriptionsOption,
}) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(
    {} as SubscriptionDetailsType[]
  );

  useEffect(() => {
    setSubscriptionDetails(
      mockedArraySubOptions.filter((el) => el.value === subscriptionsOption)
    );
  }, [subscriptionsOption]);

  console.log(subscriptionDetails);

  const onSubmit = (data: CardInterface) => {
    setRegisterState(data);
    localStorage.setItem("isUserSetPlan", "true");
    setIsShowModalWithSub(false);
  };

  const { handleChange, values, handleSubmit, touched, errors } =
    useFormik<CardInterface>({
      initialValues: {
        cardNumber: "",
        names: "",
        expiratioDate: "",
        ccvNumber: "",
      },
      validationSchema: cardSchema,
      onSubmit,
    });

  const isAnyError =
    (errors.cardNumber && touched.cardNumber) ||
    (errors.names && touched.names) ||
    (errors.expiratioDate && touched.expiratioDate) ||
    (errors.ccvNumber && touched.ccvNumber);

  const addSlashToExpireDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 2 && !e.target.value.includes("/")) {
      e.target.value = `${e.target.value}/`;
    } else if (e.target.value.length === 5) {
      e.target.value = e.target.value.slice(0, 5);
    }
    handleChange(e);
  };

  const addSpaceBetweenNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if ([4, 9, 14, 19].includes(e.target.value.length)) {
      e.target.value = `${e.target.value} `;
    }
    handleChange(e);
  };

  return (
    <div className="flex gap-16 items-center justify-center h-[768px] w-[1280px]">
      <SubscriptionsOption
        price={subscriptionDetails[0]?.price}
        subscriptionsOption={subscriptionDetails[0]?.subscriptionsOption}
        value={subscriptionDetails[0]?.value}
        pickedSubscriptionOption={subscriptionDetails[0]?.value}
        isOpacity={true}
      />
      <button
        className="fixed top-8 left-8 min-w-[180px] min-h-[32px] flex items-center gap-3 py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 border border-black-70 rounded-[20px]"
        onClick={() =>
          onChangePage(RegistrationPagesEnums.subscriptionsOptions)
        }
      >
        <ArrowLeftIcon /> Wybierz inny plan
      </button>
      <div
        className={`flex flex-col max-w-[448px] min-h-[405px] p-8 shadow-cardDefault rounded-[20px] font-satoshi ${
          isAnyError && "shadow-cardError"
        }`}
      >
        <p className="mb-6 font-black text-2xl leading-2xl tracking-md text-black-10">
          Subskrypcja
        </p>
        <span className="font-black text-1xl leading-1xl tracking-md text-black-10 mb-6">
          XX PLN
          <span className="font-normal text-xl leading-xl tracking-sm">
            /mies.
          </span>
        </span>
        <p className="font-normal text-md leading-md tracking-sm text-black-20 mb-6">
          Powyższa kwota będzie pobierana z twojej karty co miesiąc. Pamiętaj w
          każdym momencie możesz zrezygnować z subskrybcji.
        </p>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="flex flex-col"
        >
          <Input
            labelContent="Numer karty"
            type="text"
            placeholder="Podaj numer karty"
            name="cardNumber"
            value={values.cardNumber}
            onHandleUserForm={addSpaceBetweenNumber}
            isTouched={touched.cardNumber}
            validationError={errors.cardNumber}
          />
          <Input
            labelContent="Imię i nazwisko"
            type="text"
            placeholder="Imię i nazwisko (posiadacz karty)"
            name="names"
            value={values.names}
            onHandleUserForm={handleChange}
            isTouched={touched.names}
            validationError={errors.names}
          />
          <div className="flex gap-2 justify-between">
            <Input
              labelContent="Data ważności"
              type="text"
              placeholder="MM/YY"
              name="expiratioDate"
              value={values.expiratioDate}
              onHandleUserForm={addSlashToExpireDate}
              isTouched={touched.expiratioDate}
              validationError={errors.expiratioDate}
              max={5}
            />
            <Input
              labelContent="Numer CCV"
              type="text"
              placeholder="CCV"
              name="ccvNumber"
              value={values.ccvNumber}
              onHandleUserForm={handleChange}
              isTouched={touched.ccvNumber}
              validationError={errors.ccvNumber}
            />
          </div>
          <div className="flex gap-2 my-6">
            <InfoIcon />
            <span className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-70">
              Twoja płatność obsługiwana będzie przez LOREMLOREM
            </span>
          </div>
          <div className="flex flex-col gap-3">
            <button
              className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2"
              type="submit"
            >
              Zatwierdź
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
