import { FunctionComponent, useState } from "react";
import Notification from "./Notification/Notification";
import { CheckIcon } from "../Icons/Check";

interface Props {
  email: string;
  handleInCorrectTypedEmail: () => void;
}

const body = document.querySelector("body");

export const ResetPassInfoAfterSended: FunctionComponent<Props> = ({
  email,
  handleInCorrectTypedEmail,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const splitedArrayEmail = email.split("");
  const indexAt = splitedArrayEmail.indexOf("@");
  const encryptedEmail = `${splitedArrayEmail
    .slice(0, 3)
    .join("")}${splitedArrayEmail
    .slice(3, indexAt - 5)
    .join("")}*****@***${splitedArrayEmail
    .slice(indexAt + 3, splitedArrayEmail.length)
    .join("")}`;

  const sendEmailAgain = () => {
    if (body) body.style.overflow = "hidden";
    setShowNotification(true);
  };

  return (
    <>
      <div className="flex flex-col w-[455px] min-h-[335px] p-8 shadow-cardDefault rounded-[20px] gap-6">
        <p className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10">
          Link został wysłany
        </p>
        <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-20">
          Wysłaliśmy link na adres: {encryptedEmail}. Otwórz go by uzyskać
          możliwość zmiany hasła. Jeżeli nie otrzymałeś maila z linkiem prosimy
          o sprawdzenie folderu spam w przeciwnym razie klinij przycisk “Wyślij
          ponownie”.
        </p>
        <button
          className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-bold text-md leading-md text-white self-baseline py-2"
          onClick={sendEmailAgain}
        >
          Wyślij ponownie
        </button>
        <button
          onClick={handleInCorrectTypedEmail}
          className="underline font-satoshi font-normal text-sm leading-sm tracking-sm text-main flex"
        >
          Podałem zły adres e-mail
        </button>
      </div>
      {/* TUTAJ trzeba jeszcze ogarnac w momencie wprowadzania api error icon */}
      {showNotification && (
        <Notification
          setShowNotification={setShowNotification}
          body={body}
          content="Mail został wysłany ponownie!"
          backgroundColor="bg-secondary"
          icon={<CheckIcon className="fill-black-30" />}
        />
      )}
    </>
  );
};
