import { useState, useEffect } from "react";
import { useSolveTaskContext } from "../Context/TaskContext";
import { CheckIcon } from "../Icons/Check";
import { CheckedWithBackgroundIcon } from "../Icons/CheckedWithBackground";
import { CrossIcon } from "../Icons/Cross";
import { CrossWithBackgroundIcon } from "../Icons/CrossWithBackground";

interface Props {
  alertContent: string;
}

export const Alert: React.FC<Props> = ({ alertContent }) => {
  const [isAlertClosed, setIsAlertClosed] = useState(true);
  const { solvedTask } = useSolveTaskContext();

  const handleCloseAlert = () => setIsAlertClosed(false);

  useEffect(() => {
    if (!solvedTask.isLoading) {
      setTimeout(() => {
        handleCloseAlert();
      }, 5000);
    }
  }, [solvedTask.isLoading]);

  const backgroundColor = solvedTask.isError
    ? "bg-errorPopupBgc"
    : "bg-successPopupBgc";
  const textColor = solvedTask.isError
    ? "text-errorPopupText"
    : "text-successPopupText";

  return (
    <>
      {!solvedTask.isLoading && isAlertClosed && (
        <div
          className={`mx-4 lg:mx-0 h-20 w-[400px] flex items-center gap-4 rounded-md p-4 ${backgroundColor}
        fixed right-3 bottom-3`}
        >
          {solvedTask.isError ? (
            <div className="relative">
              <CrossWithBackgroundIcon />
              <CrossIcon className="fill-errorPopupText absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 w-[7px] h-[7px]" />
            </div>
          ) : (
            <div className="relative">
              <CheckedWithBackgroundIcon />
              <CheckIcon className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4" />
            </div>
          )}
          <span
            className={`font-inter font-semibold text-lg leading-md tracking-sm ${textColor}`}
          >
            {alertContent}
          </span>
          <button onClick={handleCloseAlert} className="ml-auto">
            <CrossIcon
              className={`cursor-pointer ${
                solvedTask.isError
                  ? "fill-errorPopupText"
                  : "fill-successPopupText"
              }`}
            />
          </button>
        </div>
      )}
    </>
  );
};
