interface Props {
  className?: string;
}

export const CheckIcon: React.FC<Props> = ({
  className = "fill-[#22C55E]",
}) => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.41081 0.0775146L3.75664 5.73835L1.42247 3.41085L0.244141 4.58918L3.75831 8.09501L10.5896 1.25585L9.41081 0.0775146Z" />
    </svg>
  );
};
