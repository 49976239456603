interface Props {
  className?: string;
}

export const WarningSharpAnglesIcon: React.FC<Props> = ({
  className = "fill-black-50",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.9531 0H5.05084C4.81973 0 4.58862 0.0977777 4.42862 0.257778L0.259731 4.42667C0.0997309 4.58667 0.00195312 4.81778 0.00195312 5.04889V10.9422C0.00195312 11.1822 0.0997309 11.4044 0.259731 11.5733L4.41973 15.7333C4.58862 15.9022 4.81973 16 5.05084 16H10.9442C11.1842 16 11.4064 15.9022 11.5753 15.7422L15.7353 11.5822C15.9042 11.4133 15.9931 11.1911 15.9931 10.9511V5.04889C15.9931 4.80889 15.8953 4.58667 15.7353 4.41778L11.5753 0.257778C11.4153 0.0977777 11.1842 0 10.9531 0ZM8.00195 12.7111C7.36195 12.7111 6.8464 12.1956 6.8464 11.5556C6.8464 10.9156 7.36195 10.4 8.00195 10.4C8.64195 10.4 9.15751 10.9156 9.15751 11.5556C9.15751 12.1956 8.64195 12.7111 8.00195 12.7111ZM8.00195 8.88889C7.51306 8.88889 7.11306 8.48889 7.11306 8V4.44444C7.11306 3.95556 7.51306 3.55556 8.00195 3.55556C8.49084 3.55556 8.89084 3.95556 8.89084 4.44444V8C8.89084 8.48889 8.49084 8.88889 8.00195 8.88889Z" />
    </svg>
  );
};
