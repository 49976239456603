import { SetStateAction, useState, FunctionComponent } from "react";
import {
  RegistrationPagesEnums,
  SubscriptionsOptionsEnums,
} from "../../constants/PopupWithoutPlanEnums";
import {
  CardInterface,
  PageInterface,
  SubscriptionsOptionsTypes,
} from "../../types/RegisterCardSubOptions";
import { AddCardPayment } from "../AddCardPayment/AddCardPayment";
import { SubscriptionsOptions } from "../SubscriptionsOptions/SubscriptionsOptions";

interface PlanAndCardRegisterInterface extends CardInterface {
  subscriptionsOptions: SubscriptionsOptionsTypes;
}

interface Props {
  setIsShowModalWithSub: React.Dispatch<SetStateAction<boolean>>;
}

export const ModalWithoutPlan: FunctionComponent<Props> = ({
  setIsShowModalWithSub,
}) => {
  const [activePage, setActivePage] = useState(
    RegistrationPagesEnums.subscriptionsOptions
  );
  const [planAndCardRegister, setPlanAndCardRegister] =
    useState<PlanAndCardRegisterInterface>({
      cardNumber: "",
      names: "",
      expiratioDate: "",
      ccvNumber: "",
      subscriptionsOptions: SubscriptionsOptionsEnums.basic,
    });

  const handleChangePage = (newPage: RegistrationPagesEnums) =>
    setActivePage(newPage);

  const handleRegisterState = (
    data: CardInterface | SubscriptionsOptionsTypes
  ) => {
    if (typeof data === "object" && "cardNumber" in data) {
      setPlanAndCardRegister((prevState) => ({
        ...prevState,
        cardNumber: data.cardNumber,
        names: data.names,
        expiratioDate: data.expiratioDate,
        ccvNumber: data.ccvNumber,
      }));
      return;
    }
    setPlanAndCardRegister((prevState) => ({
      ...prevState,
      subscriptionsOptions: data,
    }));
  };

  const pages: PageInterface[] = [
    {
      name: RegistrationPagesEnums.subscriptionsOptions,
      component: (
        <SubscriptionsOptions
          setRegisterState={handleRegisterState}
          onChangePage={handleChangePage}
          setIsShowModalWithSub={setIsShowModalWithSub}
        />
      ),
    },
    {
      name: RegistrationPagesEnums.payment,
      component: (
        <AddCardPayment
          setRegisterState={handleRegisterState}
          onChangePage={handleChangePage}
          setIsShowModalWithSub={setIsShowModalWithSub}
          subscriptionsOption={planAndCardRegister.subscriptionsOptions}
        />
      ),
    },
  ];

  return (
    <div className="bg-shadeWhite absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 rounded-[20px]">
      {pages.find((page) => page.name === activePage)?.component}
    </div>
  );
};
