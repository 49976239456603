import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const CornerCtaIcon: FunctionComponent<IconProps> = ({
  className = "fill-[#00EDD9]",
}) => {
  return (
    <svg
      width="126"
      height="86"
      viewBox="0 0 126 86"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="93" cy="93" r="93" />
    </svg>
  );
};
