import { FunctionComponent, useEffect, useState, useRef } from "react";
import { useAppDispatch } from "../../../store";
import { changeProjectTitle } from "../../../store/slices/projectsSlice";
import { updateTitleProject } from "../../../api";
import { ListOfProjectsType } from "../../../types/ListOfPojects";

interface Props {
  projectTitle: string;
  subject: string;
  createdAt: string;
  response: string;
  isUserCanReturnProject: boolean;
  id: number;
  setClickedProjectId: React.Dispatch<React.SetStateAction<number>>;
  isEditModeTitleFolder: boolean;
  setIsEditModeTitleFolder: React.Dispatch<React.SetStateAction<boolean>>;
  clickedProjectId: number;
  headerRef: React.RefObject<HTMLDivElement>;
  setProjectDetails: React.Dispatch<
    React.SetStateAction<ListOfProjectsType | null>
  >;
}

export const Project: FunctionComponent<Props> = ({
  projectTitle,
  subject,
  createdAt,
  response,
  id,
  setClickedProjectId,
  isEditModeTitleFolder,
  setIsEditModeTitleFolder,
  clickedProjectId,
  headerRef,
  setProjectDetails,
  isUserCanReturnProject,
}) => {
  const dispatch = useAppDispatch();
  const isActive = clickedProjectId === id;
  const [isUserClickedNotInCard, setIsUserClickedNotInCard] = useState(false);
  const correctDate = createdAt;
  const cutString = (maxLengthString: number, text: string): string => {
    return `${text.slice(0, maxLengthString)}...`;
  };

  const cardRef = useRef<any>();

  const handleVisibilityInput = () =>
    setIsEditModeTitleFolder((prevState) => !prevState);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeProjectTitle({ value: e.target.value, id }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateTitleProject(id, projectTitle);
      handleVisibilityInput();
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTitle]);

  useEffect(() => {
    let handler = (e: any) => {
      if (
        !cardRef.current?.contains(e.target) &&
        !headerRef.current?.contains(e.target)
      ) {
        setIsUserClickedNotInCard(true);
        setClickedProjectId(-1);
      } else {
        setIsUserClickedNotInCard(false);
      }
    };

    document.addEventListener("mousedown", handler);
  });

  return (
    <div
      className="w-[220px] h-[203px] cursor-pointer"
      onClick={() => setClickedProjectId(id)}
      ref={cardRef}
    >
      <div
        onDoubleClick={() =>
          setProjectDetails({
            attributes: {
              projectTitle,
              subject,
              createdAt,
              response,
              isUserCanReturnProject,
            },
            id,
          })
        }
        className={`w-[220px] h-[160px] bg-white rounded-[10px] overflow-hidden ${
          isActive && !isUserClickedNotInCard
            ? "border-[2px] border-button-default"
            : "border-[0.5px] border-stroke-border-card shadow-app-work-card"
        }`}
      >
        <p className="text-[8px] m-2 font-satoshi font-semibold text-black-30">
          {projectTitle}
        </p>
        <p className="text-[6px] m-2 font-satoshi font-normal text-black-30 break-words">
          {response}
        </p>
      </div>
      {isEditModeTitleFolder && clickedProjectId === id ? (
        <input
          type="text"
          value={projectTitle}
          className="w-full bg-transparent outline-none text-md text-center"
          onChange={handleChangeName}
          autoFocus
        />
      ) : (
        <span
          className="font-satoshi font-bold text-sm leading-sm tracking-sm text-black-10 text-center block mt-[7px]"
          onDoubleClick={handleVisibilityInput}
        >
          {projectTitle.length >= 28
            ? cutString(28, projectTitle)
            : projectTitle}
        </span>
      )}

      <div className="flex items-center justify-center gap-[6px] font-satoshi font-normal text-sm leading-sm tracking-sm text-center text-black-30">
        <span>{subject.length >= 16 ? cutString(16, subject) : subject}</span>
        <div className="h-[18px] w-[1px] bg-black-90"></div>
        <span>{correctDate}</span>
      </div>
    </div>
  );
};
