
export enum ROUTES {
  login = "/login",
  mainPage = "/",
  outcome = "/outcome",
  register = "/register",
  welcome = "/welcome",
  resetPassword = "/reset-password",
  createNewPassword = "/create-new-password",
  dashboard = "/dashboard",
  newPasswordSuccess = "/new-password/success"
}