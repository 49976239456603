import { Link } from "react-router-dom";
import { login } from "../../api";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { setToken } from "../../helpers/auth";
import { Input } from "../../components/Input/Input";
import { useState } from "react";
import { ValidationError } from "../../components/ValidationError/ValidationError";
import { LogoIcon } from "../../components/Icons/Logo";

interface LoginInterface {
  email: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState(false);

  const onSubmit = (data: LoginInterface) => {
    setLoginError(false);
    handleLogin(data);
  };

  const { handleChange, values, handleSubmit, touched } = useFormik<LoginInterface>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });

  const handleLogin = async (formLogin: LoginInterface) => {
    try {
      const { data } = await login({
        identifier: formLogin.email,
        password: formLogin.password,
      });
      setToken(data);
      navigate(ROUTES.mainPage);
    } catch (error) {
      setLoginError(true);
      console.log(error);
    }
  };

  return (
    <div className="flex gap-10 items-center justify-center h-screen">
      <div className="fixed top-[18px] left-4 w-[180px] h-8 bg-button-default"></div>
      <LogoIcon />
      <div
        className={`flex flex-col min-w-[448px] min-h-[405px] p-8 shadow-cardDefault rounded-[20px] ${
          loginError && "shadow-cardError"
        }`}
      >
        <p className="mb-6 font-black font-satoshi text-2xl leading-2xl tracking-md text-black-10">
          Logowanie
        </p>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="flex flex-col"
        >
          <Input
            labelContent="Adres e-mail"
            type="email"
            placeholder="Podaj swój adres e-mail"
            name="email"
            value={values.email}
            onHandleUserForm={handleChange}
            isTouched={touched.email}
          />
          {loginError && (
            <ValidationError error="Podany adres e-mail lub hasło są nieprawidłowe." />
          )}
          <Input
            labelContent="Hasło"
            type="password"
            placeholder="Wprowadź hasło"
            name="password"
            value={values.password}
            onHandleUserForm={handleChange}
            isTouched={touched.password}
          />
          {loginError && (
            <ValidationError error="Podany adres e-mail lub hasło są nieprawidłowe." />
          )}
          <Link
            to={ROUTES.resetPassword}
            className="underline font-satoshi font-normal text-sm leading-sm tracking-sm text-main mt-2 mb-6"
          >
            Nie pamiętasz hasła?
          </Link>
          <div className="flex flex-col gap-3">
            <button
              className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2"
              type="submit"
            >
              Zaloguj się
            </button>
            <div className="bg-black-90 w-full h-[1px]"></div>
            <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
              Nie masz konta?
              <Link to={ROUTES.register} className="text-main underline ml-1">
                Zarejestruj się!
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
