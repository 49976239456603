import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const FolderIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-original",
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_315_2712"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path d="M0 1.90735e-06H32V32H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_315_2712)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.818581 29.1729C1.3828 29.7372 2.14805 30.0541 2.94581 30.0541H29.0541C29.8519 30.0541 30.617 29.7372 31.1812 29.1729C31.7454 28.6088 32.0624 27.8437 32.0624 27.0458V7.9667C32.0624 7.16876 31.7454 6.40361 31.1812 5.83947C30.6171 5.27535 29.852 4.95833 29.0541 4.95833H12.8151C12.5477 4.95833 12.2912 4.85211 12.102 4.66298L10.2663 2.82705C9.70204 2.26294 8.93676 1.94589 8.13894 1.94589H2.94581C1.28434 1.94589 -0.0625 3.29273 -0.0625 4.9542V27.0458C-0.0625 27.8436 0.254436 28.6088 0.818581 29.1729C0.8186 29.173 0.818561 29.1729 0.818581 29.1729ZM2.94581 28.0541C2.67835 28.0541 2.42188 27.9478 2.23285 27.7588C2.04378 27.5697 1.9375 27.3132 1.9375 27.0458V4.9542C1.9375 4.3973 2.38891 3.94589 2.94581 3.94589H8.13894C8.40629 3.94589 8.66272 4.05206 8.85187 4.2411C8.85192 4.24115 8.85182 4.24105 8.85187 4.2411L10.6878 6.07717C11.252 6.64128 12.0173 6.95833 12.8151 6.95833H29.0541C29.3214 6.95833 29.5779 7.06456 29.767 7.25368C29.9561 7.4428 30.0624 7.69927 30.0624 7.9667V27.0458C30.0624 27.3131 29.9561 27.5696 29.767 27.7587C29.578 27.9478 29.3215 28.0541 29.0541 28.0541H2.94581Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.94581 28.0541C2.67833 28.0541 2.42186 27.9478 2.23291 27.7588L2.2328 27.7587C2.04373 27.5697 1.9375 27.3133 1.9375 27.0458V12.9875C1.9375 12.7201 2.04374 12.4636 2.2328 12.2746L2.23291 12.2744C2.42186 12.0855 2.67833 11.9792 2.94581 11.9792H29.0541C29.3215 11.9792 29.5779 12.0854 29.767 12.2745C29.9561 12.4636 30.0624 12.7201 30.0624 12.9875V27.0458C30.0624 27.3132 29.9561 27.5697 29.767 27.7588C29.5779 27.9479 29.3215 28.0541 29.0541 28.0541H2.94581ZM0.818701 29.1731C1.38287 29.7373 2.1481 30.0541 2.94581 30.0541H29.0541C29.852 30.0541 30.6171 29.7372 31.1812 29.173C31.7454 28.6089 32.0624 27.8437 32.0624 27.0458V12.9875C32.0624 12.1897 31.7454 11.4244 31.1812 10.8603C30.6171 10.2961 29.852 9.9792 29.0541 9.9792H2.94581C2.14805 9.9792 1.38276 10.2961 0.818584 10.8603C0.254339 11.4246 -0.0625 12.1898 -0.0625 12.9875V27.0458C-0.0625 27.8436 0.254351 28.6087 0.818584 29.1729C0.818623 29.173 0.818662 29.173 0.818701 29.1731Z"
        />
      </g>
    </svg>
  );
};
