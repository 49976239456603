import { FunctionComponent } from "react";
import { Input } from "../Input/Input";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

interface Props {
  email: string;
  handleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailValidation: () => void;
  isEmailCorrect: boolean;
}

export const ResetPasswordCard: FunctionComponent<Props> = ({
  email,
  handleEmail,
  emailValidation,
  isEmailCorrect,
}) => {
  return (
    <div
      className={`flex flex-col w-[455px] min-h-[359px] p-8 rounded-[20px] gap-6 ${
        isEmailCorrect ? "shadow-cardDefault" : "shadow-cardError"
      }`}
    >
      <p className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10">
        Resetowanie hasła
      </p>
      <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-20">
        Prosimy o podanie adresu e-mail, na którym zarejestrowane jest twoje
        konto. Wyślemy na niego link do zresetowania hasła.
      </p>
      <Input
        labelContent="Adres e-mail"
        type="email"
        placeholder="Podaj adres e-mail twojego konta"
        name="changeEmail"
        value={email}
        onHandleUserForm={handleEmail}
        isTouched={true}
        validationError={
          isEmailCorrect
            ? ""
            : "Nieprawidłowy adres e-mail. Używaj tylko cyfr, liter lub znaków +-_."
        }
      />
      <button
        className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2"
        onClick={emailValidation}
      >
        Wyślij link
      </button>
      <div className="flex gap-3">
        <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
          Masz już konto?
          <Link to={ROUTES.register} className="text-main underline ml-1">
            Zaloguj się!
          </Link>
        </p>
        <div className="h-[18px] w-[1px] bg-black-90"></div>
        <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
          Nie masz konta?
          <Link to={ROUTES.register} className="text-main underline ml-1">
            Zarejestruj się!
          </Link>
        </p>
      </div>
    </div>
  );
};
