import { FunctionComponent } from "react";
import { Project } from "./Project/Project";
import { useEffect, useState } from "react";
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { LineWhen } from "../LineWhen/LineWhen";

interface Props {
  setClickedProjectId: React.Dispatch<React.SetStateAction<number>>;
  setIsEditModeTitleFolder: React.Dispatch<React.SetStateAction<boolean>>;
  filteredProjects: ListOfProjectsType[];
  isEditModeTitleFolder: boolean;
  clickedProjectId: number;
  headerRef: React.RefObject<HTMLDivElement>;
  setProjectDetails: React.Dispatch<
    React.SetStateAction<ListOfProjectsType | null>
  >;
}

export const ListOfProjects: FunctionComponent<Props> = ({
  setClickedProjectId,
  filteredProjects,
  isEditModeTitleFolder,
  setIsEditModeTitleFolder,
  clickedProjectId,
  headerRef,
  setProjectDetails,
}) => {
  const [today, setToday] = useState([] as ListOfProjectsType[]);
  const [yesterday, setYesterday] = useState([] as ListOfProjectsType[]);
  const [previousSevenDays, setPreviousSevenDays] = useState(
    [] as ListOfProjectsType[]
  );
  const [thisMonth, setThisMonth] = useState([] as ListOfProjectsType[]);
  const [previousSixMonths, setPreviousSixMonths] = useState(
    [] as ListOfProjectsType[]
  );
  const [thisYear, setThisYear] = useState([] as ListOfProjectsType[]);
  const [previousYear, setPreviousYear] = useState([] as ListOfProjectsType[]);

  const date = new Date();

  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  const checkTheSameDate = (createdData: number, yearToCompare: number) => {
    return createdData === yearToCompare ? true : false;
  };

  const resetProjectsStates = () => {
    setToday([]);
    setYesterday([]);
    setPreviousSevenDays([]);
    setThisMonth([]);
    setPreviousSixMonths([]);
    setThisYear([]);
    setPreviousYear([]);
  };

  useEffect(() => {
    resetProjectsStates();
    filteredProjects.forEach((project) => {
      const date = project.attributes.createdAt.split(".");
      const yearOfCreatedProject = +date[2];
      const monthOfCreatedProject = +date[1];
      const dayOfCreatedProject = +date[0];

      const isCurrentYear = checkTheSameDate(yearOfCreatedProject, currentYear);
      const isCurrentMonth = checkTheSameDate(
        monthOfCreatedProject,
        currentMonth
      );

      const isYearMonthTheSame = isCurrentYear && isCurrentMonth;

      if (isYearMonthTheSame && dayOfCreatedProject === currentDay) {
        setToday((prevState) => [...prevState, project]);
        return;
      }

      if (isYearMonthTheSame && dayOfCreatedProject + 1 === currentDay) {
        setYesterday((prevState) => [...prevState, project]);
        return;
      }

      if (isYearMonthTheSame && currentDay - 6 <= dayOfCreatedProject) {
        setPreviousSevenDays((prevState) => [...prevState!, project]);
        return;
      }

      if (isYearMonthTheSame) {
        setThisMonth((prevState) => [...prevState, project]);
        return;
      }

      if (isCurrentYear && monthOfCreatedProject + 5 >= currentMonth) {
        setPreviousSixMonths((prevState) => [...prevState!, project]);
        return;
      }

      if (isCurrentYear) {
        return setThisYear((prevState) => [...prevState, project]);
      }

      if (checkTheSameDate(yearOfCreatedProject, currentYear - 1)) {
        return setPreviousYear((prevState) => [...prevState, project]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjects]);

  return (
    <div className="p-4 flex flex-col gap-[18px] sticky overflow-auto mt-16">
      {today.length > 0 && (
        <>
          <LineWhen when="Dziś" />
          <div className="flex gap-8 w-full flex-wrap">
            {today.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {yesterday.length > 0 && (
        <>
          <LineWhen when="Wczoraj" />
          <div className="flex gap-8 w-full flex-wrap">
            {yesterday.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {previousSevenDays.length > 0 && (
        <>
          <LineWhen when="Poprzednie 7 dni" />
          <div className="flex gap-8 w-full flex-wrap">
            {previousSevenDays!.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {thisMonth.length > 0 && (
        <>
          <LineWhen when="Ostatni miesiąc" />
          <div className="flex gap-8 w-full flex-wrap">
            {thisMonth.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {previousSixMonths.length > 0 && (
        <>
          <LineWhen when="Ostatnie sześć miesięcy w tym roku" />
          <div className="flex gap-8 w-full flex-wrap">
            {previousSixMonths.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {thisYear.length > 0 && (
        <>
          <LineWhen when="Ten rok" />
          <div className="flex gap-8 w-full flex-wrap">
            {thisYear.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
      {previousYear.length > 0 && (
        <>
          <LineWhen when="Poprzedni rok" />
          <div className="flex gap-8 w-full flex-wrap">
            {previousYear.map((day) => (
              <Project
                {...day.attributes}
                id={day.id}
                setClickedProjectId={setClickedProjectId}
                key={day.id}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
