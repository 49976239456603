import { InfoMobile } from "./components/InfoMobile/InfoMobile";
import { RoutesLogic } from "./pages/RoutesLogic";

function App() {
  const windowWidth = window.innerWidth;
  const breakPointMobile = 992;

  if (windowWidth < breakPointMobile) {
    return <InfoMobile />;
  }

  return (
    <>
      <RoutesLogic />
    </>
  );
}

export default App;
