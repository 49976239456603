import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { LogoIcon } from "../../components/Icons/Logo";
import { ArrowWithShadowIcon } from "../../components/Icons/ArrowWithShadow";

export const CreatedNewPasswordSuccess = () => {
  return (
    <div className="flex items-center justify-center h-screen gap-20">
      <LogoIcon />
      <div className="flex flex-col w-[450px] min-h-[514px] p-8 rounded-[20px] gap-6 justify-center items-center shadow-cardDefault">
        <ArrowWithShadowIcon />
        <p className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10 text-center">
          Hasło zostało pomyślnie zmienione
        </p>
        <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-20 text-center">
          Twoje hasło zostało pomyślnie zmienione! Możesz teraz się zalogować za
          pomocą nowego hasła.
        </p>
        <Link
          to={ROUTES.login}
          className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2 text-center"
        >
          Zaloguj się
        </Link>
      </div>
    </div>
  );
};
