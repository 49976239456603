import { useState, FunctionComponent } from "react";
import {
  RegistrationPagesEnums,
  SubscriptionsOptionsEnums,
} from "../../constants/PopupWithoutPlanEnums";
import { PropsType } from "../../types/RegisterCardSubOptions"; 
import { SubscriptionsOption } from "./SubscriptionsOption/SubscriptionsOption";

export type SubscriptionDetailsType = {
  subscriptionsOption: string;
  price: number;
  value: SubscriptionsOptionsEnums;
};

export const mockedArraySubOptions: SubscriptionDetailsType[] = [
  {
    subscriptionsOption: "Free",
    price: 0,
    value: SubscriptionsOptionsEnums.free,
  },
  {
    subscriptionsOption: "Basic",
    price: 10,
    value: SubscriptionsOptionsEnums.basic,
  },
  {
    subscriptionsOption: "Pro",
    price: 20,
    value: SubscriptionsOptionsEnums.pro,
  },
];

export const SubscriptionsOptions: FunctionComponent<PropsType> = ({
  setRegisterState,
  onChangePage,
  setIsShowModalWithSub,
}) => {
  const [pickedSubscriptionOption, setPickedSubscriptionOption] = useState(
    SubscriptionsOptionsEnums.basic
  );

  const handlePickedSubscription = (option: SubscriptionsOptionsEnums) => {
    setPickedSubscriptionOption(option);
  };

  const confirmSubscriptionPick = () => {
    setRegisterState(pickedSubscriptionOption);
    if (pickedSubscriptionOption !== SubscriptionsOptionsEnums.free) {
      onChangePage(RegistrationPagesEnums.payment);
      return;
    }
    localStorage.setItem("isUserSetPlan", "true");
    setIsShowModalWithSub(false);
  };

  return (
    <div className="flex flex-col items-center justify-center h-[768px] w-[1280px] font-satoshi">
      <h3 className="font-black text-2xl leading-2xl tracking-md text-black-10">
        Wybierz plan
      </h3>
      <p className="max-w-[768px] text-center font-normal text-md leading-md tracking-sm text-black-30 mt-8 mb-8">
        Lorem ipsum dolor sit amet consectetur. Sit tellus elementum eu
        consequat ligula. Elementum sit fringilla adipiscing quam malesuada. Sed
        nunc massa lacus arcu euismod sapien.
      </p>

      <div className="flex gap-8 border-y border-black-90 py-8">
        {mockedArraySubOptions.map((el) => (
          <SubscriptionsOption
            {...el}
            key={el.value}
            pickedSubscriptionOption={pickedSubscriptionOption}
            handlePickedSubscription={handlePickedSubscription}
            isOpacity={false}
          />
        ))}
      </div>
      <button
        className="min-w-[228px] min-h-[60px] py-[14px] px-8 bg-button-default rounded-[20px] font-satoshi font-medium text-xl leading-xl tracking-sm text-white mt-8"
        onClick={confirmSubscriptionPick}
      >
        Potwierdź wybór
      </button>
    </div>
  );
};
