import { FunctionComponent } from "react";
import { SubscriptionsOptionsEnums } from "../../../constants/PopupWithoutPlanEnums";

interface Props {
  subscriptionsOption: string;
  price: number;
  value: SubscriptionsOptionsEnums;
  pickedSubscriptionOption: SubscriptionsOptionsEnums;
  handlePickedSubscription?: (option: SubscriptionsOptionsEnums) => void;
  isOpacity: boolean;
}

export const SubscriptionsOption: FunctionComponent<Props> = ({
  subscriptionsOption,
  price,
  value,
  pickedSubscriptionOption,
  handlePickedSubscription,
  isOpacity,
}) => {
  return (
    <div
      className={`bg-white w-[360px] min-h-[342px] rounded-[20px] p-6 cursor-pointer relative shadow-subscription-card border-[0.5px] border-black-70 ${
        isOpacity ? "opacity-50 pointer-events-none" : ""
      }`}
      onClick={() =>
        handlePickedSubscription && value && handlePickedSubscription(value)
      }
    >
      {value === SubscriptionsOptionsEnums.basic && (
        <div className="absolute flex items-center justify-center bg-secondary w-[70px] h-[34px] rounded-[100px] -top-[17px] right-0 shadow-subscription-card-badge">
          badge
        </div>
      )}
      <p className="font-normal text-md leading-md tracking-sm text-black-20 mb-3">
        {subscriptionsOption}
      </p>
      <span className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10">
        {price} PLN
      </span>
      <span className="font-satoshi font-normal text-1xl leading-1xl tracking-md text-black-10">
        /mies
      </span>
      <div className="flex flex-col gap-3 font-normal text-md leading-md tracking-sm text-black-30 my-6  font-satoshi">
        <div className="flex gap-3">
          <div className="w-6 h-6 bg-black-orginal"></div>
          <p>not / included</p>
        </div>
        <div className="flex gap-3">
          <div className="w-6 h-6 bg-black-orginal"></div>
          <p>not / included</p>
        </div>
        <div className="flex gap-3">
          <div className="w-6 h-6 bg-black-orginal"></div>
          <p>not / included</p>
        </div>
      </div>
      <div className="w-full h-[1px] bg-black-90"></div>
      <div className="flex justify-center">
        <div className="w-5 h-5 border border-black-90 rounded-full mt-6 relative cursor-pointer">
          {pickedSubscriptionOption === value && (
            <div className="w-[14px] h-[14px] bg-main rounded-full absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"></div>
          )}
        </div>
      </div>
    </div>
  );
};
