import axios, { AxiosError } from "axios";
import { Opinion, ReportError } from "../types/Opinion";
import { TaskForm } from "../types/TaskForm";
import { clearToken, getToken, getUserEmail } from "../helpers/auth";
import { ROUTES } from "../constants/Routes";
const API = axios.create({ baseURL: process.env.REACT_APP_BASE_API });

const logOutCodes = [401, 403]


API.interceptors.request.use(config => {
  const jwt = getToken();
  if (jwt) {
    config!.headers!.Authorization = `Bearer ${jwt}`;
  }
  return config;
}, error => Promise.reject(error));

API.interceptors.response.use(
  response => response,
  error => {
    if (logOutCodes.includes(error?.response?.status)) {
      console.log("clear token");
      clearToken();
      window.location.href = ROUTES.login
    }
    return Promise.reject(error);
  }
);


interface User {
  identifier: string;
  password: string;
}

interface RegisterUser {
  username: string;
  email: string;
  password: string;
  // isRegulationsChecked: boolean;
}

export const StrapiAxiosError = AxiosError<{ error: { message: string } }>;

export const register = (user: RegisterUser) =>
  API.post("/api/auth/local/register", user);
export const me = (user: RegisterUser) =>
  API.post("/api/auth/local/register", user);
export const login = (user: User) =>
  API.post("/api/auth/local", user);
export const solveTask = (task: TaskForm) =>
  API.post("/api/ai/generate", task);
export const getSolvedTask = (taskId: string) =>
  API.get(`/api/ai/response?taskid=${taskId}`);
export const postOpinion = (opinion: Opinion) =>
  API.post("/api/opinions", { data: opinion });
export const postReportErrors = (opinion: ReportError) =>
  API.post("/api/errors", { data: opinion });
  export const getProjects = () =>
    API.get(
      `/api/ai-requests?populate=user&filters[user][email][$eq]=${getUserEmail()}`
    );
export const blockReturnAfterFirstPreview = (id: number) =>
  API.put(`/api/ai-requests/${id}`, { data: { isUserCanReturnProject: false } });
export const updateTitleProject = (id: number, projectTitle: string) => API.put(`/api/ai-requests/${id}`, {data: {projectTitle}})

export const createFolderForNewProjects = (id: number) =>
  API.post(`/api/folder-project-users/user-folders/primary-folder/${id}`);
