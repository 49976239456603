import { useState } from "react";
import { Header } from "../../components/Header/Header";
import { Alert } from "../../components/Outcome/Alert";
import { Result } from "../../components/Outcome/Result";

export const Outcome = () => {
  const [alertContent, setAlertContent] = useState(
    "Twoja praca została wygenerowana pomyślnie!"
  );
  const [closeReviews, setCloseReviews] = useState(false);

  return (
    <>
      <Header />
      <Result
        setAlertContent={setAlertContent}
        closeReviews={closeReviews}
        setCloseReviews={setCloseReviews}
      />
      <Alert alertContent={alertContent} />
    </>
  );
};
