import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const CopyToClipboardIcon: FunctionComponent<IconProps> = ({
  className = "fill=[#A5ABC0]",
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72853 1.19319C2.37251 1.19319 2.08389 1.47806 2.08389 1.82946V9.54552C2.08389 9.89692 1.82549 10.1818 1.46947 10.1818C1.11345 10.1818 0.875 9.89692 0.875 9.54552V1.9091C0.875 0.854623 1.74087 0 2.80923 0H9.25655C9.61257 0 9.90119 0.235058 9.90119 0.586751C9.90119 0.938444 9.61257 1.19319 9.25655 1.19319H2.72853ZM3.45387 4.45448C3.45387 3.4 4.31974 2.54537 5.38809 2.54537H11.1908C12.2591 2.54537 13.125 3.4 13.125 4.45448V12.0909C13.125 13.1454 12.2591 14 11.1908 14H5.38809C4.31974 14 3.45387 13.1454 3.45387 12.0909V4.45448ZM5.3074 3.73856C4.95138 3.73856 4.66276 4.02344 4.66276 4.37483V12.1705C4.66276 12.5219 4.95138 12.8068 5.3074 12.8068H11.2712C11.6272 12.8068 11.9158 12.5219 11.9158 12.1705V4.37513C11.9158 4.02374 11.6272 3.73886 11.2712 3.73886H5.3077L5.3074 3.73856Z"
      />
    </svg>
  );
};
