import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const ReturnIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-30",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.6667 5C15.6333 5 16.5225 5.23752 17.3333 5.71256C18.1546 6.1876 18.8046 6.83271 19.2825 7.6483C19.7608 8.4639 20 9.34694 20 10.2966C20 11.2463 19.7608 12.0155 19.2825 12.8208C18.8042 13.6364 18.1546 14.2819 17.3333 14.7565C16.5225 15.2315 15.6333 15.4691 14.6667 15.4691H4.88875C4.64958 15.4691 4.44167 15.3813 4.265 15.2059C4.08833 15.0408 4 14.9584 4 14.7106C4 14.4627 4.08833 14.2666 4.265 14.0911C4.44167 13.9157 4.64958 13.8279 4.88875 13.8279H14.6667C15.3529 13.8279 15.9663 13.6782 16.5067 13.379C17.0471 13.0798 17.4683 12.6615 17.7696 12.1243C18.0708 11.5872 18.2217 10.9781 18.2217 10.297C18.2217 9.61591 18.0708 8.89797 17.7696 8.36086C17.4683 7.82375 17.0471 7.40044 16.5067 7.09092C15.9663 6.79133 15.3525 6.64195 14.6667 6.64195H8.44458C8.20542 6.64195 7.9975 6.55422 7.82083 6.37877C7.64417 6.21367 7.55583 6.13132 7.55583 5.88346C7.55583 5.63559 7.64417 5.43945 7.82083 5.264C7.9975 5.08855 8.20542 5.00083 8.44458 5.00083H14.6667V5ZM4.35875 15.2986C4.17167 15.1128 4.07792 14.9994 4.07792 14.7102C4.07792 14.4209 4.17167 14.1937 4.35875 13.9976L7.82083 10.5594C7.98708 10.3839 8.195 10.2962 8.44458 10.2962C8.69417 10.2962 8.90208 10.3839 9.06833 10.5594C9.245 10.7348 9.33333 10.9413 9.33333 11.1788C9.33333 11.437 9.245 11.6435 9.06833 11.7983L5.60625 15.1128C5.55417 15.1645 5.51792 15.0871 5.49708 14.8806C5.47625 14.6845 5.47625 14.6071 5.49708 14.4006C5.51792 14.2045 5.55417 14.1321 5.60625 14.1838L9.06833 17.4979C9.245 17.663 9.33333 17.8695 9.33333 18.1174C9.33333 18.3652 9.245 18.5717 9.06833 18.7368C8.90208 18.9123 8.69417 19 8.44458 19C8.195 19 7.98708 18.9123 7.82083 18.7368L4.35875 15.2986Z" />
    </svg>
  );
};
