import { FunctionComponent } from "react";

type IconProps = {
  className?: string;
};

export const InfoIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-70",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_427_6033)">
        <path d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57847 12.422 0 8 0ZM8 14.8837C4.20431 14.8837 1.11628 11.7957 1.11628 8C1.11628 4.20428 4.20431 1.11628 8 1.11628C11.7957 1.11628 14.8837 4.20428 14.8837 8C14.8837 11.7957 11.7957 14.8837 8 14.8837Z" />
        <path d="M8.0003 6.66866C7.52642 6.66866 7.18945 6.86878 7.18945 7.16362V11.1757C7.18945 11.4284 7.52642 11.6811 8.0003 11.6811C8.45311 11.6811 8.82164 11.4284 8.82164 11.1757V7.16356C8.82164 6.86875 8.45311 6.66866 8.0003 6.66866Z" />
        <path d="M8.00019 4.194C7.51578 4.194 7.13672 4.5415 7.13672 4.94166C7.13672 5.34184 7.51581 5.69988 8.00019 5.69988C8.47406 5.69988 8.85319 5.34184 8.85319 4.94166C8.85319 4.5415 8.47403 4.194 8.00019 4.194Z" />
      </g>
      <defs>
        <clipPath id="clip0_427_6033">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
