export const NoResultIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.9129 58.347C64.0258 58.4323 64.1413 58.5005 64.2331 58.5936C69.08 63.4359 73.9256 68.2807 78.7699 73.1243C80.3942 74.7484 80.4113 77.2568 78.8079 78.8495C77.2399 80.4067 74.7246 80.3805 73.1252 78.7812C68.277 73.939 63.4314 69.0954 58.5858 64.2519C58.494 64.1601 58.4139 64.0538 58.3378 63.9646C48.8435 71.1446 38.3414 73.573 26.8015 70.6186C18.1443 68.4027 11.2873 63.4385 6.2475 56.0682C-3.98556 41.0994 -1.42565 20.9039 12.2976 8.89603C25.9631 -3.06199 46.0789 -2.86258 59.5279 8.87636C73.7274 21.2699 76.2073 42.9623 63.9143 58.347H63.9129ZM35.9246 7.98557C20.6255 7.96327 8.10806 20.1981 7.99522 35.6773C7.88107 51.3008 20.3329 63.6471 35.5309 63.8426C51.162 64.0433 63.7922 51.5435 63.8316 36.021C63.871 20.4408 51.4401 7.98557 35.9246 7.98557Z"
        fill="#3F455A"
      />
      <path
        d="M32.4667 35.6497C32.3145 35.5343 32.199 35.4647 32.1072 35.3729C28.9988 32.2689 25.8931 29.1637 22.7873 26.0571C21.9384 25.2083 21.7718 24.1286 22.3333 23.1617C23.0406 21.9416 24.7266 21.6596 25.792 22.5845C25.9075 22.6842 26.0138 22.7944 26.1227 22.9019C29.1982 25.9757 32.2738 29.0508 35.3481 32.1273C35.4399 32.2191 35.5055 32.3358 35.6026 32.4683C35.7561 32.3227 35.8493 32.2375 35.9385 32.1482C39.0456 29.043 42.1513 25.9364 45.2583 22.8324C46.1388 21.9534 47.2659 21.7881 48.2368 22.3903C49.4807 23.1617 49.6749 24.9013 48.6226 25.9574C46.4471 28.1404 44.2651 30.3155 42.087 32.4946C41.1134 33.468 40.1412 34.4428 39.165 35.4136C39.081 35.4975 38.9721 35.5553 38.8081 35.6733C38.9904 35.815 39.1046 35.8846 39.1978 35.9777C42.3219 39.0974 45.4447 42.2171 48.5635 45.3408C49.7825 46.5621 49.4584 48.4723 47.9062 49.1387C47.09 49.489 46.3028 49.3696 45.5916 48.8278C45.4538 48.7229 45.3305 48.5943 45.2072 48.471C42.1159 45.3814 39.0246 42.2919 35.9359 39.1997C35.8519 39.1158 35.7902 39.0095 35.7194 38.9137C35.6721 38.9006 35.6249 38.8862 35.5777 38.8731C35.5094 38.9859 35.4596 39.1171 35.3691 39.2076C32.2279 42.3549 29.0828 45.4995 25.9364 48.6428C25.2528 49.325 24.249 49.5152 23.3975 49.1374C22.5118 48.7438 21.9686 47.8767 22.0171 46.9164C22.0499 46.2604 22.3504 45.7357 22.8096 45.2778C25.6792 42.4152 28.5435 39.5474 31.4118 36.6835C31.7358 36.3595 32.0678 36.0446 32.4706 35.6523L32.4667 35.6497Z"
        fill="#3F455A"
      />
    </svg>
  );
};
