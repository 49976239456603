import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const FileIcon: FunctionComponent<IconProps> = ({
  className = "fill=[#A5ABC0]",
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.2051 2.73492L9.68389 0.411523C9.39615 0.146289 9.01979 0 8.6238 0H3.29247C2.44187 0 1.75 0.67457 1.75 1.50391V12.4961C1.75 13.3254 2.44187 14 3.29247 14H11.145C11.9956 14 12.6875 13.3254 12.6875 12.4961V3.82758C12.6875 3.41605 12.5117 3.01793 12.2051 2.7352V2.73492ZM11.3879 3.28125H9.29407C9.21667 3.28125 9.15385 3.22 9.15385 3.14453V1.22062L11.3879 3.28125ZM10.8727 12.9062H3.57292C3.18618 12.9062 2.87179 12.5997 2.87179 12.2227V1.77734C2.87179 1.40055 3.18618 1.09375 3.57292 1.09375H8.03205V3.14453C8.03205 3.82812 8.59295 4.375 9.29407 4.375H11.5738V12.2227C11.5738 12.5997 11.2595 12.9062 10.8727 12.9062Z" />
    </svg>
  );
};
