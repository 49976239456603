import { useState, FunctionComponent, useEffect, useRef } from "react";
import { FolderType } from "../../types/Folder";
import { CheckIcon } from "../Icons/Check";
import { CrossIcon } from "../Icons/Cross";
import { FolderIcon } from "../Icons/Folder";

interface Props {
  addNewFolder: (newFolder: FolderType) => void;
}

export interface KeyboardEvent {
  key: string;
}

export const AddNewFolder: FunctionComponent<Props> = ({ addNewFolder }) => {
  const [newFolder, setNewFolder] = useState("");
  const [isNewFolderDisplay, setIsNewFolderDisplay] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNewFolder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolder(e.target.value);
  };

  const handleShowInput = () => {
    setIsNewFolderDisplay(true);
  };

  const closeAndReset = () => {
    setIsNewFolderDisplay(false);
    setNewFolder("");
  };

  const handleAddNewFolder = () => {
    if (newFolder.length !== 0) {
      addNewFolder({
        content: newFolder,
        projects: [],
        id: Math.random().toString(),
      });
    }
    closeAndReset();
  };

  useEffect(() => {
    if (inputRef.current === null) return;
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAddNewFolder();
      } else if (e.key === "Escape") {
        closeAndReset();
      }
    };

    inputRef.current.addEventListener("keydown", handler);

    return () => {
      if (inputRef.current === null) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputRef.current.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFolder, inputRef.current, isNewFolderDisplay]);

  return (
    <>
      {isNewFolderDisplay && (
        <div className="flex justify-between gap-2 items-center p-1 rounded-md cursor-pointer font-satoshi font-normal text-md leading-sm tracking-sm w-[188px] h-[26px] mb-5 pt-4">
          <div className="flex gap-2 items-center text-black-10">
            <FolderIcon className="fill-black-70 w-4 h-4" />
            <input
              className="w-full text-black-10 focus:text-black-10 border-black-70 focus:border-2 focus:border-highlightedText p-1 outline-none rounded-md"
              placeholder="Nowy folder"
              onChange={handleNewFolder}
              value={newFolder}
              ref={inputRef}
              autoFocus
            />
          </div>
          <div className="flex gap-2">
            <button onClick={closeAndReset}>
              <CrossIcon className="w-[10.5px] h-[10.5px] fill-black-70" />
            </button>
            <button onClick={handleAddNewFolder}>
              <CheckIcon className="w-[11.62px] h-[9px] fill-black-70" />
            </button>
          </div>
        </div>
      )}

      <button
        onClick={handleShowInput}
        className="font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 py-[7px] px-6 border border-black-buttonBorder rounded-[20px] my-[10px] w-[188px] bg-white"
      >
        Utwórz nowy folder
      </button>
    </>
  );
};
