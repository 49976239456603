import { FunctionComponent, useRef, useEffect } from "react";

interface Props {
  options: {
    icon: JSX.Element;
    text: string;
    id: string;
  }[];
  className?: string;
  closeAllDropDown: () => void;
}

export const DropDownMenu: FunctionComponent<Props> = ({
  options,
  className = "top-[32px]",
  closeAllDropDown,
}) => {
  const dropDown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let handler = (e: any) => {
      if (!dropDown.current?.contains(e.target)) {
        closeAllDropDown();
      }
    };

    document.addEventListener("mousedown", handler);
  });

  return (
    <div
      className={`absolute right-0 py-2 px-3 flex flex-col gap-2 bg-white rounded shadow-app-dropdown-menu  ${className}`}
      ref={dropDown}
    >
      {options.map((option, index) => (
        <button
          className="flex flex-col gap-2"
          onClick={() => console.log("sasd")}
          key={option.id}
        >
          <span className="flex gap-[10px] items-center">
            <span>{option.icon}</span>
            <p className="whitespace-nowrap font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
              {option.text}
            </p>
          </span>
          {options.length - 1 === index ? (
            ""
          ) : (
            <span className="w-full h-[1px] bg-black-90"></span>
          )}
        </button>
      ))}
    </div>
  );
};
