import { createSlice } from "@reduxjs/toolkit";
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { convertDate } from "../../helpers/convertDate";

export const ProjectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [] as ListOfProjectsType[],
  },
  reducers: {
    addProjects(state, action) {
      state.projects = [...action.payload];
    },
    changeProjectTitle(state, action) {
      const editingProject = state.projects.findIndex(
        (item) => item.id === action.payload.id
      );
      state.projects[editingProject].attributes.projectTitle =
        action.payload.value;
    },
    updateFieldAfterApiCall(state, action) {
      action.payload.attributes.createdAt = convertDate(action.payload.attributes.createdAt)

      const editingProject = state.projects.findIndex(
        (item) => item.id === action.payload.id
      );
      state.projects[editingProject].attributes = {
        ...action.payload.attributes,
      };
    },
  },
});

export const { addProjects, changeProjectTitle, updateFieldAfterApiCall } =
  ProjectsSlice.actions;
export default ProjectsSlice.reducer;