import { FunctionComponent } from "react";
import { CrossIcon } from "../../Icons/Cross";

interface Props {
  closeModal: () => void;
  content: string;
  checkIsFunctionDeleteFolderExist: () => void;
}

export const DeleteOnlyFolder: FunctionComponent<Props> = ({
  closeModal,
  content,
  checkIsFunctionDeleteFolderExist,
}) => {
  return (
    <div className="flex flex-col">
      <button className="p-[16.75px] self-end" onClick={closeModal}>
        <CrossIcon />
      </button>
      <p className="px-8 mb-6 font-satoshi font-bold text-xl text-black-10">
        Ostrzeżenie: Usuwanie folderu i jego zawartości
      </p>
      <p className="px-8 font-satoshi font-normal text-md leading-md tracking-sm text-black-20">
        Usunięcie folderu <b>“{content}”</b> spowoduje trwałe usunięcie samego
        folderu bez żadnych prac znajdujących się wewnątrz niego. Po tej akcji
        prace te będą dostępne w folderze “Wszystkie prace”. Czy na pewno chcesz
        to zrobić? Upewnij się, że nie potrzebujesz już tego folderu przed
        podjęciem decyzji.
      </p>
      <div className="flex gap-3 self-end pb-8 pr-8 pt-6">
        <button
          className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 border border-black-70 rounded-[20px]"
          onClick={closeModal}
        >
          Anuluj
        </button>
        <button
          className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-white bg-error
         rounded-[20px]"
          onClick={checkIsFunctionDeleteFolderExist}
        >
          Usuwam sam folder
        </button>
      </div>
    </div>
  );
};
