import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const LogoutIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-20",
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66634 9.66662V10.9999C1.66634 12.4733 2.86034 13.6666 4.33301 13.6666H10.9997C12.473 13.6666 13.6663 12.4733 13.6663 10.9999C13.6663 8.78062 13.6663 5.21995 13.6663 2.99995C13.6663 1.52728 12.473 0.333282 10.9997 0.333282C9.09367 0.333282 6.23967 0.333282 4.33301 0.333282C2.86034 0.333282 1.66634 1.52728 1.66634 2.99995V4.33328C1.66634 4.70128 1.96501 4.99995 2.33301 4.99995C2.70101 4.99995 2.99967 4.70128 2.99967 4.33328C2.99967 4.33328 2.99967 3.70862 2.99967 2.99995C2.99967 2.26395 3.59701 1.66662 4.33301 1.66662H10.9997C11.7363 1.66662 12.333 2.26395 12.333 2.99995V10.9999C12.333 11.7366 11.7363 12.3333 10.9997 12.3333C9.09367 12.3333 6.23967 12.3333 4.33301 12.3333C3.59701 12.3333 2.99967 11.7366 2.99967 10.9999C2.99967 10.2919 2.99967 9.66662 2.99967 9.66662C2.99967 9.29928 2.70101 8.99995 2.33301 8.99995C1.96501 8.99995 1.66634 9.29928 1.66634 9.66662ZM6.72367 6.33328L5.86167 5.47194C5.60167 5.21128 5.60167 4.78928 5.86167 4.52862C6.12234 4.26862 6.54434 4.26862 6.80434 4.52862L8.80434 6.52862C9.06501 6.78928 9.06501 7.21128 8.80434 7.47194L6.80434 9.47194C6.54434 9.73194 6.12234 9.73194 5.86167 9.47194C5.60167 9.21128 5.60167 8.78928 5.86167 8.52862L6.72367 7.66662H0.999674C0.631674 7.66662 0.333008 7.36795 0.333008 6.99995C0.333008 6.63262 0.631674 6.33328 0.999674 6.33328H6.72367Z"
      />
    </svg>
  );
};
