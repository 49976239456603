import * as yup from "yup";
export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const onlyStringRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]+$/u;
const onlyNumberRegex = /^[0-9 ]+$/;

let typeOfCard = "OtherCard";

function isAmericanExpressCard(cardNumber: string): boolean {
  cardNumber = cardNumber.replace(/\s/g, "");

  if (!/^\d+$/.test(cardNumber)) {
    return false;
  }

  return cardNumber.length === 15 && /^3[47]/.test(cardNumber);
}

export const userDetailsRegisterSchema = yup.object().shape({
  email: yup
    .string()
    .email(
      "Nieprawidłowy adres e-mail. Używaj tylko cyfr, liter lub znaków +-_."
    )
    .required("E-mail jest wymagany"),
  password: yup
    .string()
    .min(
      8,
      "Hasło musi składać się z minimum 8 znaków, jednej dużej litery, małej oraz cyfry."
    )
    .matches(
      passwordRules,
      "Hasło musi składać się z minimum 8 znaków, jednej dużej litery, małej oraz cyfry."
    )
    .required("Hasło jest wymagane"),
  isRegulationsChecked: yup
    .boolean()
    .isTrue(
      "Aby założyć konto, należy zaakceptować nasz regulamin i politykę prywatności."
    )
    .required(),
});

export const cardSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .matches(onlyNumberRegex, "Nieprawidłowy numer karty. Używaj tylko cyfr.")
    .test(
      "validationCardNumbers",
      "Nieprawidłowy numer karty",
      (cardNumber: string | undefined): boolean => {
        if (!cardNumber) return false;

        typeOfCard = isAmericanExpressCard(cardNumber)
          ? "American Express"
          : "OtherCard";

        cardNumber = cardNumber.replace(/\s/g, "");
        if (!/^\d+$/.test(cardNumber)) {
          return false;
        }

        const cardNumberArray: number[] = cardNumber
          .split("")
          .reverse()
          .map(Number);

        for (let i = 1; i < cardNumberArray.length; i += 2) {
          cardNumberArray[i] *= 2;
        }

        const sum: number = cardNumberArray.reduce((acc, curr) => {
          if (curr >= 10) {
            acc += (curr % 10) + Math.floor(curr / 10);
          } else {
            acc += curr;
          }
          return acc;
        }, 0);

        return sum % 10 === 0;
      }
    )
    .required("Nieprawidłowy numer karty. Używaj tylko cyfr."),
  names: yup
    .string()
    .matches(
      onlyStringRegex,
      "Imię i nazwisko posiadacza karty musi składać się wyłacznie z liter."
    )
    .required("Imię i nazwisko jest wymagane."),
  expiratioDate: yup
    .string()
    .test(
      "validationExpiratioDate",
      "Nieprawidłowa data",
      (expiryDate: string | undefined): boolean => {
        if (!expiryDate) return false;
        const [month, year] = expiryDate.replace(/\s/g, "").split("/");

        if (isNaN(Number(month)) || isNaN(Number(year))) {
          return false;
        }

        const fullYear =
          Number(year) < 100 ? 2000 + Number(year) : Number(year);

        const expiry = new Date(fullYear, Number(month) - 1, 1);
        const now = new Date();

        return expiry > now;
      }
    )
    .required("Nieprawidłowa data."),
  ccvNumber: yup
    .string()
    .test(
      "validationCCV",
      "Nieprawidłowy numer CCV",
      (cvv: string | undefined): boolean => {
        if (!cvv) return false;

        if (!/^\d+$/.test(cvv)) {
          return false;
        }

        switch (typeOfCard) {
          case "OtherCard":
            return cvv.length === 3;
          case "American Express":
            return cvv.length === 4;
          default:
            return false;
        }
      }
    )
    .required("Nieprawidłowy numer."),
});
