import { Link } from "react-router-dom";
import { LogoIcon } from "../../components/Icons/Logo";
import { ROUTES } from "../../constants/Routes";

const Welcome = () => {
  return (
    <div className="flex items-center justify-center h-screen gap-20">
      <div className="fixed top-[18px] left-4 w-[180px] h-8 bg-button-default"></div>
      <LogoIcon />
      <div className="flex flex-col">
        <h3 className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10">
          Witaj w Napiszemy.To
        </h3>
        <Link
          to={ROUTES.register}
          className="font-satoshi font-medium text-md leading-md tracking-sm text-white min-w-[384px] h-[40px] rounded-[20px] py-2 px-6 bg-button-default hover:bg-button-hover transition-[background-color] outline-none focus:border focus:border-button-focus text-center mt-10"
        >
          Zarejestruj się przez e-mail
        </Link>
        <div className="my-3 flex gap-6 items-center">
          <div className="w-full bg-black-90 h-[1px]"></div>
          <span className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
            albo
          </span>
          <div className="w-full bg-black-90 h-[1px]"></div>
        </div>
        <Link
          to={ROUTES.login}
          className="font-satoshi font-medium text-md leading-md tracking-sm text-black-10 border border-black-10 min-w-[384px] h-[40px] rounded-[20px] py-2 px-6 bg-white text-center"
        >
          Zaloguj się
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
