import React from "react";

interface ModalProps {
  isOpen: boolean;
  children: JSX.Element;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  className,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[1000] bg-modal-verlay">
      <div
        className={`fixed top-12 left-1/2 -translate-x-1/2 z-[1000] rounded-[20px] flex flex-col bg-white max-w-[704px] ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
