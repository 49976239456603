import { ChangeEvent, FunctionComponent } from 'react';
import { ValidationError } from "../ValidationError/ValidationError";

interface InputProps {
  type: string;
  placeholder: string;
  name: string;
  value: string | number | null;
  onHandleUserForm: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  validationError?: string;
  isTouched?: boolean;
  id?: string;
  max?: number;
  min?: number;
  disabled?: boolean;
  labelContent?: string;
}

export const Input: FunctionComponent<InputProps> = ({
  type,
  placeholder,
  name,
  value,
  onHandleUserForm,
  validationError,
  isTouched,
  className,
  id,
  max,
  min,
  disabled,
  labelContent,
}) => {
  const isErrorState = validationError && isTouched;
  return (
    <div className="flex flex-col gap-0.5 mt-2 font-satoshi font-normal text-md leading-md tracking-sm text-black-20">
      <label htmlFor={id}>{labelContent}</label>
      <input
        className={`${className} w-full h-10 border outline-none rounded-md py-2 px-3 text-black-70 font-normal text-md leading-md font-satoshi tracking-sm border-black-70 active:border-black-30 active:text-black-30 focus:border-secondary focus:text-black-30 disabled:border-black-70 disabled:text-black-30 disabled:opacity-[35%] ${
          value ? "text-black-30 border-black-30" : ""
        } ${isErrorState && "border-error hover:border-hover-error"}`}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value ? value : ""}
        onChange={onHandleUserForm}
        max={max}
        min={min}
        id={id}
        disabled={disabled}
      />
      {isErrorState && <ValidationError error={validationError} />}
    </div>
  );
};
