import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getProjects } from "../../api";
import { DashboardHeader } from "../../components/DashboardHeader/DashboardHeader";
import { ListOfProjects } from "../../components/DashboardProjetcsList/ListOfProjects";
import { CornerCtaIcon } from "../../components/Icons/CornerCta";
import { PlusIcon } from "../../components/Icons/Plus";
import { ModalWithoutPlan } from "../../components/ModalWithoutPlan/ModalWithoutPlan";
import { folderArrayMock } from "../../components/Sidebar/DummyData";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { PrimaryFolderName } from "../../constants/PrimaryFolderName";
import { ROUTES } from "../../constants/Routes";
import { convertDate } from "../../helpers/convertDate";
import { FolderType } from "../../types/Folder";
import { useAppDispatch, useAppSelector } from "../../store";
import { addProjects } from "../../store/slices/projectsSlice"; 
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { NoResultIcon } from "../../components/Icons/NoResult";
import { PreviewProject } from "../../components/PreviewProject/PreviewProject";

export const Dashboard = () => {
  const [idSelectedFolder, setIdSelectedFolder] = useState<string>(
    PrimaryFolderName.all
  );
  const [folders, setFolders] = useState(folderArrayMock);
  const [selectedFolderName, setSelectedFolderName] = useState<string>(
    PrimaryFolderName.all
  );
  const [filteredProjects, setFilteredProjects] = useState(
    [] as ListOfProjectsType[]
  );
  const [clickedProjectId, setClickedProjectId] = useState(-1);
  const [isEditModeTitleFolder, setIsEditModeTitleFolder] = useState(false);
  const [isShowModalWithSub, setIsShowModalWithSub] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const [searchProjects, setSearchProjects] = useState("");
  const [projectDetails, setProjectDetails] =
    useState<ListOfProjectsType | null>(null);

    const allProjects = useAppSelector((state) => state.projects);
    const dispatch = useAppDispatch();

    useEffect(() => {
      const isUserSetPlan = localStorage.getItem("isUserSetPlan");
      if (isUserSetPlan) {
        setIsShowModalWithSub(false);
      }
    }, []);

    const handleSearchProjects = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchProjects(e.target.value);
    };

    const deleteSelectedProject = () => {
      setFilteredProjects((prevState) =>
        prevState.filter((project) => project.id !== clickedProjectId)
      );
      setClickedProjectId(-1);
    };

    const openInputWithTitle = () => {
      setIsEditModeTitleFolder(true);
    };

    const setFilterId = (id: string) => {
      setIdSelectedFolder(id);
    };

    const countSameNames = (name: string | FolderType) => {
      let count = 0;
      if (typeof name === "string") {
        folders.forEach((folder) => {
          if (folder.content.split(" ")[0] === name.split(" ")[0]) {
            count++;
          }
        });
      } else {
        folders.forEach((folder) => {
          if (folder.content.split(" ")[0] === name.content.split(" ")[0]) {
            count++;
          }
        });
      }
      return count;
    };

    const changeFolderTitle = (title: string) => {
      setFolders((prevState) => {
        const editedFolderIndex = prevState.findIndex(
          (index) => index.id === idSelectedFolder
        );
        const countSameName = countSameNames(title);
        if (
          countSameName > 1 &&
          title !== prevState[editedFolderIndex].content
        ) {
          prevState[editedFolderIndex].content = `${title} (${countSameName})`;
        } else {
          prevState[editedFolderIndex].content = title;
        }
        return [...prevState];
      });
    };

    const addNewFolder = (newFolder: FolderType) => {
      const countSameName = countSameNames(newFolder.content);
      if (countSameName) {
        newFolder.content = `${newFolder.content} (${countSameName})`;
      }
      setFolders((prevState) => [...prevState, newFolder]);
    };

    const deleteFolder = (id: string) => {
      setFolders((prevState) => prevState.filter((el) => el.id !== id));
    };

    useEffect(() => {
      setSelectedFolderName(
        folders.find((folder) => folder.id === idSelectedFolder)?.content!
      );
    }, [idSelectedFolder, folders]);

    useEffect(() => {
      try {
        const getData = async () => {
          const data = await getProjects();
          const convertedDate = data.data.data.map((el: any) => {
            el.attributes.createdAt = convertDate(el.attributes.createdAt);
            return el;
          });
          dispatch(addProjects(convertedDate));
        };
        getData();
      } catch (error) {
        console.log(error);
      }
    }, [dispatch]);

    useEffect(() => {
      if (searchProjects === "") {
        setFilteredProjects(allProjects.projects);
        return;
      }
      setFilteredProjects(
        allProjects.projects.filter((project) => {
          const titleMatch = project.attributes.projectTitle
            .toLowerCase()
            .includes(searchProjects.toLowerCase());
          const subjectMatch = project.attributes.subject
            .toLowerCase()
            .includes(searchProjects.toLowerCase());
          return titleMatch || subjectMatch;
        })
      );
    }, [searchProjects, allProjects.projects]);

  return (
    <>
      <div className="flex">
        <Sidebar
          idSelectedFolder={idSelectedFolder}
          folders={folders}
          setFilterId={setFilterId}
          changeFolderTitle={changeFolderTitle}
          addNewFolder={addNewFolder}
          deleteFolder={deleteFolder}
          setProjectDetails={setProjectDetails}
          isFirstLookAtProjectWithId={{
            id: projectDetails?.id!,
            isUserCanReturnProject:
              projectDetails?.attributes.isUserCanReturnProject!,
          }}
        />
        <div className="w-full ml-[220px] bg-background-bg5 h-screen">
          <DashboardHeader
            selectedFolderName={selectedFolderName}
            clickedProjectId={clickedProjectId}
            deleteSelectedProject={deleteSelectedProject}
            folders={folders}
            openInputWithTitle={openInputWithTitle}
            handleSearchProjects={handleSearchProjects}
            searchProjects={searchProjects}
            ref={headerRef}
            isUserCanReturnProject={
              projectDetails?.attributes.isUserCanReturnProject!
            }
          />
          {projectDetails === null ? (
            filteredProjects.length >= 1 ? (
              <ListOfProjects
                setClickedProjectId={setClickedProjectId}
                isEditModeTitleFolder={isEditModeTitleFolder}
                setIsEditModeTitleFolder={setIsEditModeTitleFolder}
                clickedProjectId={clickedProjectId}
                headerRef={headerRef}
                setProjectDetails={setProjectDetails}
                filteredProjects={filteredProjects}
              />
            ) : (
              <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex flex-col items-center">
                <NoResultIcon />
                <p className="font-satoshi font-bold text-lg leading-lg tracking-sm text-center text-black-10 mt-6 mb-1">
                  Brak wyników dla:
                </p>
                <p className="font-satoshi font-normal text-md leading-md tracking-sm text-center text-black-30">
                  {searchProjects}
                </p>
              </div>
            )
          ) : (
            <PreviewProject projectDetails={projectDetails} />
          )}
        </div>
      </div>
      {projectDetails === null && (
        <div className="fixed right-0 bottom-0 group/item">
          <Link
            to={ROUTES.mainPage}
            className="absolute bottom-[18px] right-4 min-w-[218px] min-h-[60px] bg-button-default py-[14px] px-6 rounded-[20px] font-satoshi font-medium text-xl leading-xl tracking-sm text-white flex gap-3 items-center z-10"
          >
            <span className="whitespace-nowrap">Generuj pracę</span>{" "}
            <PlusIcon />
          </Link>
          <div className="overflow-hidden">
            <CornerCtaIcon className="fill-secondary translate-x-4 translate-y-4 z-0 group-hover/item:translate-x-0 group-hover/item:translate-y-0 transition-[transform]" />
          </div>
        </div>
      )}
      {isShowModalWithSub === true && (
        <div className="absolute top-0 left-2/4 -translate-x-2/4 h-full w-full bg-overlayBg z-20 border-none outline-none shadow-app-dropdown-menu">
          <ModalWithoutPlan setIsShowModalWithSub={setIsShowModalWithSub} />
        </div>
      )}
    </>
  );
};

