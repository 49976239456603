import { Dispatch, SetStateAction, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { useSolveTaskContext } from "../Context/TaskContext";
import { SpinnerIcon } from "../Icons/Spinner";
import { Feedback } from "./Feedback";

interface Props {
  setAlertContent: Dispatch<SetStateAction<string>>;
  closeReviews: boolean;
  setCloseReviews: Dispatch<SetStateAction<boolean>>;
}

export const Result: React.FC<Props> = ({
  setAlertContent,
  closeReviews,
  setCloseReviews,
}) => {
  const navigation = useNavigate();
  const { solvedTask, generateAgain, resetSolvedTaskState, storedContentTask } =
    useSolveTaskContext();
  const [isOpinionExpressed, setIsOpinionExpressed] = useState(false);

  const goBackToForm = () => {
    if (closeReviews) {
      setIsOpinionExpressed(false);
      navigation(ROUTES.mainPage);
      resetSolvedTaskState();
    } else {
      setIsOpinionExpressed(true);
    }
  };
  return (
    <div>
      {solvedTask.isLoading ? (
        <div className="absolute top-1/3 left-2/4 -translate-x-2/4 flex-row justify-center items-center">
          <SpinnerIcon className="animate-spin w-20 h-20 m-auto" />
          <h1
            className="text-1xl font-bold"
            style={{ color: "rgb(161 42 255)" }}
          >
            Trwa generowanie twojej pracy
          </h1>
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <div className="flex flex-col gap-1 w-[560px]">
            <label
              htmlFor="solved-task"
              className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
            >
              Twoja praca:
            </label>
            <textarea
              placeholder="Twoja praca pojawi się tutaj..."
              className={`w-full min-h-[428px] rounded-md px-2 py-3 border ${
                solvedTask.isError ? "border-error hover:border-hover-error" : "border-black-50"
              } font-inter font-normal text-lg leading-md tracking-sm text-black-100`}
              name="solved-task"
              id="solved-task"
              value={solvedTask.message}
              onChange={() => {}}
            ></textarea>
            {solvedTask.isError ? (
              <p className="font-inter font-normal text-sm leading-sm tracking-sm text-error mt-0.5">
                Nastąpił błąd podczas generowania twojej pracy, kliknij przycisk
                “generuj ponownie” by spróbować ponownie
              </p>
            ) : null}
            {!storedContentTask.task && !solvedTask.message && (
              <Navigate to={ROUTES.mainPage} />
            )}
            <Feedback
              setAlertContent={setAlertContent}
              setCloseReviews={setCloseReviews}
              closeReviews={closeReviews}
              isOpinionExpressed={isOpinionExpressed}
              setIsOpinionExpressed={setIsOpinionExpressed}
            />
            <button
              className="w-[229px] rounded-md px-3 py-5 bg-mvpMain font-inter font-semibold text-xl leading-md text-white self-center mt-8"
              onClick={goBackToForm}
            >
              Napisz kolejną pracę!
            </button>
            {solvedTask.isError && storedContentTask.task && (
              <button
                className="w-[229px] rounded-md px-2 py-3 font-inter border border-gray-300 font-semibold text-xl leading-md text-black-75 self-center mt-3"
                onClick={generateAgain}
              >
                Generuj ponownie
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
