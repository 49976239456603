import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const SearchIcon: FunctionComponent<IconProps> = ({
  className = "fill-[#A5ABC0]",
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.20462 1.44848C4.48558 1.44848 1.45609 4.47797 1.45609 8.19702C1.45609 11.9161 4.48558 14.9515 8.20462 14.9515C9.79314 14.9515 11.2541 14.3948 12.4087 13.4705L15.2198 16.28C15.3616 16.416 15.5511 16.491 15.7476 16.489C15.9441 16.487 16.132 16.4081 16.271 16.2693C16.4101 16.1304 16.4892 15.9427 16.4915 15.7462C16.4938 15.5497 16.419 15.3601 16.2832 15.2181L13.4722 12.407C14.3973 11.2506 14.9546 9.78737 14.9546 8.19702C14.9546 4.47797 11.9237 1.44848 8.20462 1.44848ZM8.20462 2.9485C11.113 2.9485 13.4532 5.28865 13.4532 8.19702C13.4532 11.1054 11.113 13.4514 8.20462 13.4514C5.29624 13.4514 2.95609 11.1054 2.95609 8.19702C2.95609 5.28865 5.29624 2.9485 8.20462 2.9485Z" />
    </svg>
  );
};
