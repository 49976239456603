interface Props {
  className?: string;
}

export const ThumbsUpIcon: React.FC<Props> = ({
  className = "fill-black-50",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.96 16H2.24C2.77019 16 3.2 15.5702 3.2 15.04L3.2 6.72C3.2 6.18981 2.77019 5.76 2.24 5.76H0.960001C0.429807 5.76 7.73708e-07 6.18981 7.27357e-07 6.72L0 15.04C-4.6351e-08 15.5702 0.429807 16 0.96 16Z" />
      <path d="M15.6862 7.0464C15.5375 6.84532 15.3436 6.68204 15.1201 6.56976C14.8967 6.45747 14.6499 6.39932 14.3998 6.4L9.67023 6.4L10.6174 1.664C10.6574 1.46178 10.652 1.25321 10.6016 1.05333C10.5513 0.853444 10.4572 0.667216 10.3262 0.508062C10.1952 0.348908 10.0305 0.220789 9.84407 0.132935C9.6576 0.0450817 9.45397 -0.000319683 9.24784 1.69432e-06C9.00697 0.00123149 8.77045 0.0642503 8.56091 0.183029C8.35137 0.301808 8.17581 0.472371 8.05103 0.678401L4.64623 6.4H3.80783C3.82829 6.50546 3.83901 6.61258 3.83983 6.72L3.83983 15.04C3.839 15.1474 3.82829 15.2545 3.80783 15.36H12.627C12.9689 15.3603 13.3019 15.2511 13.5771 15.0484C13.8524 14.8457 14.0555 14.5602 14.1566 14.2336L15.9294 8.4736C16.0037 8.23332 16.0204 7.97891 15.9782 7.73097C15.9359 7.48303 15.8359 7.24852 15.6862 7.0464Z" />
    </svg>
  );
};
