import { useFormik } from "formik";
import { useState } from "react";
import { EducationLevels } from "../../constants/EducationLevels";
import { TaskForm } from "../../types/TaskForm";
import { CustomSelect } from "./CustomSelect";
import { schema } from "../../validation/TaskSchema";
import { WordsLevel } from "../../constants/EducationLevels";
import { Input } from "../Input/Input";
import { useSolveTaskContext } from "../Context/TaskContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

export const initialValues = {
  task: "",
  level: "",
  teachingDirection: "",
  specialization: "",
  wordsLevel: "",
  grade: 0,
  minWords: 0,
};

export const FormTask = () => {
  const errorBorder = "border-error hover:border-hover-error border-2";
  const [preventSubmit, setPreventSubmit] = useState(false);
  const { getResolveTask } = useSolveTaskContext();
  const navigation = useNavigate();

  const redirectResultPage = () => navigation(ROUTES.outcome);

  const handlePreventSubmit = () => {
    setPreventSubmit(true);
  };

  const handleWordsLevelState = (inputRadioValue: string) => {
    setFieldValue("wordsLevel", inputRadioValue);
  };

  const onSubmit = (data: TaskForm) => {
    setPreventSubmit(false);
    if (!preventSubmit) return;
    redirectResultPage();
    getResolveTask(data);
  };

  const { handleChange, values, handleSubmit, errors, touched, setFieldValue } =
    useFormik<TaskForm>({
      initialValues,
      onSubmit,
      validationSchema: preventSubmit ? schema : "",
    });

  const typeOfGrades =
    values.level === "Studia" ? [2, 3, 4, 5] : [2, 3, 4, 5, 6];

  return (
    <form
      onSubmit={handleSubmit}
      className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 max-w-[560px] flex flex-col items-center gap-8"
    >
      <div className="flex flex-col gap-1">
        <label
          htmlFor="task"
          className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
        >
          Treść zadania
        </label>
        <textarea
          onChange={handleChange}
          name="task"
          id="task"
          placeholder="Wprowadź treść zadania np.: “napisz rozprawkę na temat...”"
          className="min-h-[80px] w-[560px] max-w-[560px] px-2 py-3 border border-black-50 text-black-100 rounded-md focus:border-2 focus:border-borderPurple"
        ></textarea>
        {errors.task && touched.task && (
          <p className="text-error font-inter font-normal text-sm leading-sm tracking-sm mt-1">
            {errors.task}
          </p>
        )}
      </div>
      <div className="w-full mb-8">
        <CustomSelect
          labelTitle="Stopień nauczania"
          validationErrorBorder={errors.level && touched.level && errorBorder}
          setFieldValue={setFieldValue}
          values={[
            EducationLevels.primarySchool,
            EducationLevels.highSchool,
            EducationLevels.studies,
          ]}
          formikStateName="level"
          stateValue={values.level}
          error={errors.level}
          placeholder="Wybierz stopień nauczania"
          className="w-full"
        />
      </div>
      <div className="flex gap-6">
        <div className="flex flex-col gap-1">
          <label
            htmlFor="teachingDirection"
            className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
          >
            Kierunek nauczania
          </label>
          <Input
            type="text"
            placeholder="Np.: “Technik informatyk”"
            name="teachingDirection"
            value={values.teachingDirection}
            onHandleUserForm={handleChange}
            validationError={errors.teachingDirection}
            isTouched={touched.teachingDirection}
            className="w-[268px] h-10"
            id="teachingDirection"
            disabled={values.level === EducationLevels.primarySchool}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="specialization"
            className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
          >
            Przedmiot
          </label>
          <Input
            type="text"
            placeholder="Np.: “Język Polski”"
            name="specialization"
            value={values.specialization}
            onHandleUserForm={handleChange}
            validationError={errors.specialization}
            isTouched={touched.specialization}
            className="w-[268px] h-10"
            id="specialization"

          />
        </div>
      </div>
      <div className="flex flex-col w-full gap-2">
        <label
          htmlFor="wordsLevel"
          className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
        >
          Dobór słownictwa
        </label>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="wordsLevel"
              value={WordsLevel.easy}
              id="wordsLevel"
              onClick={() => handleWordsLevelState(WordsLevel.easy)}
              className="border-black-50 w-5 h-5 focus:border-2 focus:border-borderPurple"
            />
            <span className="font-inter font-normal text-md leading-md tracking-sm text-black-75">
              {WordsLevel.easy}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="wordsLevel"
              value={WordsLevel.medium}
              id="wordsLevel"
              className="border-black-50 w-5 h-5 focus:border-2 focus:border-borderPurple"
              onClick={() => handleWordsLevelState(WordsLevel.medium)}
            />
            <span className="font-inter font-normal text-md leading-md tracking-sm text-black-75">
              {WordsLevel.medium}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="wordsLevel"
              value={WordsLevel.specialized}
              id="wordsLevel"
              className="border-black-50 w-5 h-5 focus:border-2 focus:border-borderPurple"
              onClick={() => handleWordsLevelState(WordsLevel.specialized)}
            />
            <span className="font-inter font-normal text-md leading-md tracking-sm text-black-75">
              {WordsLevel.specialized}
            </span>
          </div>
        </div>
        {errors.wordsLevel && touched.wordsLevel && (
          <p className="text-error font-inter font-normal text-sm leading-sm tracking-sm mt-1">
            {errors.wordsLevel}
          </p>
        )}
      </div>
      <div className="flex gap-6 w-full">
        <div className="w-full">
          <CustomSelect
            labelTitle="Ocena"
            validationErrorBorder={errors.grade && touched.grade && errorBorder}
            setFieldValue={setFieldValue}
            values={typeOfGrades}
            formikStateName="grade"
            stateValue={values.grade}
            error={errors.grade}
            placeholder="Wybierz ocenę"
            className="w-[268px]"
          />
        </div>
        <div>
          <label
            htmlFor="minWords"
            className="font-inter font-normal text-lg leading-md tracking-sm text-black-100"
          >
            Ilość słów
          </label>
          <Input
            type="number"
            placeholder="Np.: 200"
            name="minWords"
            value={values.minWords}
            onHandleUserForm={handleChange}
            validationError={errors.minWords}
            isTouched={touched.minWords}
            className="w-[268px] h-10"
            id="minWords"
            max={500}
            min={10}
          />
        </div>
      </div>
      <button
        type="submit"
        onClick={handlePreventSubmit}
        className="w-[141px] min-h-[48px] py-3 px-5 bg-mvpMain font-inter font-semibold text-xl leading-md text-white rounded-md"
      >
        Napiszmy to!
      </button>
    </form>
  );
};
