import { FunctionComponent, useState } from "react";
import { PrimaryFolderName } from "../../constants/PrimaryFolderName";
import { FolderType } from "../../types/Folder";
import { AddNewFolder } from "../AddNewFolder/AddNewFolder";
import { Folder } from "../Folder/Folder";
import { LogoutIcon } from "../Icons/Logout";
import { SettingsIcon } from "../Icons/Settings";
import { UserIcon } from "../Icons/User";
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { DeleteFolderModal } from "../DeleteFolderModal/DeleteFolderModal";
interface Props {
  idSelectedFolder: string;
  folders: FolderType[];
  setFilterId: (id: string) => void;
  changeFolderTitle: (title: string) => void;
  addNewFolder: (newFolder: FolderType) => void;
  deleteFolder: (id: string) => void;
  setProjectDetails: React.Dispatch<
    React.SetStateAction<ListOfProjectsType | null>
  >;
  isFirstLookAtProjectWithId: { id: number; isUserCanReturnProject: boolean };
}

const body = document.querySelector("body");

export const Sidebar: FunctionComponent<Props> = ({
  idSelectedFolder,
  folders,
  setFilterId,
  changeFolderTitle,
  addNewFolder,
  deleteFolder,
  setProjectDetails,
  isFirstLookAtProjectWithId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentFolderDelete, setContentFolderDelete] = useState("");
  const [deleteFolderId, setDeleteFolderId] = useState("");

  return (
    <>
      <div className="w-[220px] min-h-screen p-4 border-r border-black-90 flex flex-col justify-between bg-black-10 fixed">
        <div>
          <div className="w-[180px] h-8 bg-white"></div>

          <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-white mt-6 mb-3">
            Foldery
          </p>
          <Folder
            content="Wszystkie prace"
            projects={[]}
            id={PrimaryFolderName.all}
            isActive={idSelectedFolder === PrimaryFolderName.all ? true : false}
            setFilterId={setFilterId}
            setProjectDetails={setProjectDetails}
            isFirstLookAtProjectWithId={isFirstLookAtProjectWithId}
          />
          <Folder
            content="Zedytowane prace"
            projects={[]}
            id={PrimaryFolderName.edited}
            isActive={
              idSelectedFolder === PrimaryFolderName.edited ? true : false
            }
            setFilterId={setFilterId}
            setDeleteFolderId={setDeleteFolderId}
            deleteFolderId={deleteFolderId}
            setIsOpen={setIsOpen}
            setProjectDetails={setProjectDetails}
            isFirstLookAtProjectWithId={isFirstLookAtProjectWithId}
          />
          <div className="w-[188px] h-[1px] bg-black-20 mb-5"></div>
          <div className="fixed z-0 overflow-y-auto h-[calc(100vh_-_435px)] pr-[5px]">
            {folders.map((f: FolderType) => (
              <Folder
                key={f.id}
                {...f}
                isActive={idSelectedFolder === f.id ? true : false}
                setFilterId={setFilterId}
                changeFolderTitle={changeFolderTitle}
                setDeleteFolderId={setDeleteFolderId}
                deleteFolderId={deleteFolderId}
                setIsOpen={setIsOpen}
                setContentFolderDelete={setContentFolderDelete}
                setProjectDetails={setProjectDetails}
                isFirstLookAtProjectWithId={isFirstLookAtProjectWithId}
              />
            ))}
          </div>
          <div className="fixed bottom-[164px] w-[204px] left-0 z-10 flex flex-col items-center bg-black-10">
            <AddNewFolder addNewFolder={addNewFolder} />
          </div>
        </div>
        <div className="font-satoshi text-sm leading-sm tracking-sm text-black-70 fixed bottom-0 z-10 bg-black-10 pb-[18px] pt-6">
          <p className="font-medium mb-3">Plan's name</p>
          <p className="font-normal">
            Limit prac:
            <span className="font-medium text-xl leading-md tracking-sm text-white ml-2">
              44/50
            </span>
          </p>
          <div className="w-[188px] h-[1px] bg-black-20 mt-6 mb-[25px]"></div>
          <div className="flex justify-between">
            <div className="flex items-center gap-[10px]">
              <UserIcon className="fill-black-70" />
              <span>Łysy</span>
            </div>
            <div className="flex gap-[10px] font-medium">
              <button>
                <SettingsIcon className="fill-black-90" />
              </button>
              <div className="w-[1px] h-[16px] bg-black-20"></div>
              <button>
                <LogoutIcon className="fill-black-90" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteFolderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        body={body}
        content={contentFolderDelete}
        checkIsFunctionDeleteFolderExist={() => {
          deleteFolder(deleteFolderId);
          setIsOpen(false);
        }}
      />
    </>
  );
};
