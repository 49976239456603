export const CheckedWithBackgroundIcon: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M17.3332 8.99999C17.3332 13.6021 13.6019 17.3333 8.99984 17.3333C4.39775 17.3333 0.666504 13.6021 0.666504 8.99999C0.666504 4.39791 4.39775 0.666656 8.99984 0.666656C13.6019 0.666656 17.3332 4.39791 17.3332 8.99999Z"
        fill="#22C55E"
      />
    </svg>
  );
};
