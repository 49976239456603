import { useState } from "react";
import { LogoIcon } from "../../components/Icons/Logo";
import { Input } from "../../components/Input/Input";
import { passwordRules } from "../../validation/registerSchema";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

export const CreateNewPassword = () => {
  const navigation = useNavigate();
  const [passwords, setPasswords] = useState({
    password: "",
    repeatedPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswords = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const checkIsPasswordIsNotEmpty = () =>
    passwords.password !== passwords.repeatedPassword ||
    passwords.password === "" ||
    passwords.repeatedPassword === "";

  const validatePassword = () => passwords.password.match(passwordRules);

  const checkArePasswordsMatching = () => {
    if (checkIsPasswordIsNotEmpty()) {
      setErrorMessage("Hasło się nie zgadza.");
      return false;
    }
    if (!validatePassword()) {
      setErrorMessage(
        "Hasło musi składać się z minimum 8 znaków, jednej dużej litery, małej oraz cyfry."
      );
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const changingPassword = () => {
    if(!checkArePasswordsMatching()) return;
    navigation(ROUTES.newPasswordSuccess)
    setErrorMessage("");
    // try catch zapytanie do bazy
  }

  return (
    <div className="flex h-screen items-center justify-center gap-20">
      <div className="fixed top-[18px] left-4 w-[180px] h-8 bg-button-default"></div>
      <LogoIcon />
      <div
        className={`flex flex-col w-[455px] min-h-[359px] p-8 shadow-cardDefault rounded-[20px] gap-6 ${
          !errorMessage ? "shadow-cardDefault" : "shadow-cardError"
        }`}
      >
        <p className="font-satoshi font-black text-2xl leading-2xl tracking-md text-black-10">
          Utwórz nowe hasło
        </p>
        <Input
          labelContent="Hasło"
          type="password"
          placeholder="Wprowadź nowe hasło"
          name="password"
          value={passwords.password}
          onHandleUserForm={handlePasswords}
        />
        <Input
          labelContent="Potwierdź hasło"
          type="password"
          placeholder="Wprowadź ponownie nowe hasło"
          name="repeatedPassword"
          value={passwords.repeatedPassword}
          onHandleUserForm={handlePasswords}
          isTouched={true}
          validationError={errorMessage}
        />
        <button
          className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2"
          onClick={changingPassword}
        >
          Zmień hasło
        </button>
      </div>
    </div>
  );
};
