import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const MoveToIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-30",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_368_3113)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.47256 1.03601C1.1595 1.03601 0.905726 1.32622 0.905726 1.68421V14.3157C0.905726 14.4875 0.96552 14.6525 1.07181 14.774C1.1782 14.8957 1.32243 14.9639 1.47279 14.9639C1.47763 14.9639 1.48245 14.964 1.48725 14.9642H14.5271C14.6776 14.9642 14.8217 14.8959 14.9277 14.7745L14.9281 14.7741C15.0343 14.6528 15.094 14.488 15.094 14.316V3.40704C15.094 3.23516 15.0342 3.07026 14.9277 2.94828C14.8217 2.82686 14.6776 2.75858 14.5271 2.75858H6.40761C6.02495 2.75858 5.65867 2.58459 5.38846 2.27598L4.47022 1.22593C4.36383 1.10427 4.21959 1.03601 4.06923 1.03601H1.47256ZM1.45542 15.9638C1.07955 15.9586 0.719845 15.7857 0.453458 15.4811C0.183334 15.1722 0.03125 14.7529 0.03125 14.3157V1.68421C0.03125 0.773931 0.676544 0.0360107 1.47256 0.0360107H4.06923C4.45137 0.0360107 4.81808 0.209507 5.08856 0.518823L6.00643 1.56844C6.1131 1.69027 6.2573 1.75858 6.40761 1.75858H14.5271C14.9092 1.75858 15.2761 1.93204 15.5464 2.24161C15.8163 2.5506 15.9685 2.96945 15.9685 3.40704V14.316C15.9685 14.7527 15.8169 15.172 15.5464 15.4812C15.2761 15.7907 14.9092 15.9642 14.5271 15.9642H1.47279C1.46698 15.9642 1.46118 15.9641 1.45542 15.9638Z"
        />
        <path d="M7.77479 6.49064L9.21199 8.13414H5.65625C5.39727 8.13414 5.1875 8.37402 5.1875 8.67018C5.1875 8.96634 5.39727 9.20622 5.65625 9.20622H9.21223L7.77503 10.8497C7.59198 11.059 7.59198 11.3984 7.77503 11.6077C7.95807 11.817 8.2548 11.817 8.43808 11.6077C8.92863 11.0467 10.6485 9.08025 10.678 9.04594C10.8585 8.83608 10.8571 8.49891 10.6754 8.2912L8.43808 5.73268C8.25503 5.52335 7.95831 5.52335 7.77503 5.73268C7.59174 5.942 7.59198 6.28131 7.77503 6.49064H7.77479Z" />
      </g>
      <defs>
        <clipPath id="clip0_368_3113">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
