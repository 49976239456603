import { FunctionComponent, useState, useEffect } from "react";
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { Modal } from "../Modal/Modal";
import { ReturnIcon } from "../Icons/Return";

interface Props {
  projectDetails: ListOfProjectsType;
}

export const PreviewProject: FunctionComponent<Props> = ({
  projectDetails: {
    attributes: {
      createdAt,
      projectTitle,
      response,
      subject,
      isUserCanReturnProject,
    },
  },
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showOneTime, setShowOneTime] = useState(true);
  const [showOneTimeInputValue, setShowOneTimeInputValue] = useState(false);

  useEffect(() => {
    const doNotShowModalNextTime = localStorage.getItem(
      "doNotShowModalNextTime"
    );
    if (doNotShowModalNextTime) {
      setShowOneTime(true);
    } else {
      setShowOneTime(false);
    }
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[954px] relative">
          <div className="pb-12 px-16 rounded-t-[10px] shadow-preview-project mt-[92px] bg-white max-w-[954px] overflow-auto h-[calc(100vh_-_92px)]">
            <div className="w-full h-12 bg-preview-project absolute left-0"></div>
            <div className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30 flex gap-[6px] mb-6 mt-12">
              <p>{subject}</p>
              <div className="h-[18px] w-[1px] bg-black-90"></div>
              <p>{createdAt}</p>
            </div>
            <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-30">
              Treść zadania:
            </p>
            <h4 className="font-satoshi font-black text-1xl leading-1xl tracking-md text-black-10 mb-6">
              {projectTitle}
            </h4>
            <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-30">
              Odpowiedź:
            </p>
            <p className="font-satoshi font-normal text-lg leading-lg tracking-sm text-black-10 break-words">
              {response}
            </p>
          </div>
        </div>
      </div>
      {isUserCanReturnProject && !showOneTime && (
        <Modal isOpen={isOpen} className="top-1/2 -translate-y-1/2">
          <div className="min-w-[628px] min-h-[600px] bg-shadeWhite rounded-[20px] flex flex-col gap-8 items-center justify-center py-16 px-8">
            <div className="w-20 h-20 bg-white rounded-full relative shadow-notification">
              <ReturnIcon className="fill-black-30 w-12 h-[42px] absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4" />
            </div>

            <h4 className="font-satoshi font-black text-1xl leading-1xl tracking-md text-center text-black-10">
              <span className="text-error">Ważna</span> informacja dotycząca
              zwrotu pracy
            </h4>

            <div className="flex flex-col gap-4">
              <p className="font-satoshi font-normal text-md leading-md tracking-sm text-black-10 text-center">
                Przypominamy, że w naszej aplikacji masz możliwość zwrotu pracy
                tylko na pierwszym podglądzie zaraz po jej wygenerowaniu. Jeśli
                opuścisz stronę i ponownie wejdziesz w pracę, nie będziesz
                miał/a możliwości zwrócenia pracy.
              </p>

              <p className="font-satoshi font-bold text-lg leading-lg tracking-sm text-center text-black-10">
                Dlatego bardzo ważne jest, aby dokładnie przejrzeć pracę i
                upewnić się, że spełnia Twoje wymagania od razu po jej
                wygenerowaniu.
              </p>

              <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-center text-black-30">
                Funkcja zwrotu pracy znajduje się na górnym pasku aplikacji. W
                przypadku jakichkolwiek wątpliwości lub pytań, skontaktuj się z
                nami.
              </p>
            </div>
            <div className="flex gap-2">
              <input
                id="no-show"
                type="checkbox"
                checked={showOneTimeInputValue}
                onChange={() =>
                  setShowOneTimeInputValue((prevState) => !prevState)
                }
                className="w-5 h-5 cursor-pointer"
              />
              <label
                className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-10 cursor-pointer"
                htmlFor="no-show"
              >
                Nie pokazuj ponownie tego komunikatu
              </label>
            </div>
            <button
              className="w-64 min-h-10 py-2 px-6 rounded-[20px] bg-button-default flex items-center justify-center font-satoshi font-medium text-md leading-md tracking-sm text-white"
              onClick={() => {
                setIsOpen(false);
                if (showOneTimeInputValue) {
                  localStorage.setItem("doNotShowModalNextTime", "true");
                }
              }}
            >
              Rozumiem
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
