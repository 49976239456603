import { MainPage } from "./MainPage/MainPage";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Login/Login";
import { AuthorizedOnly } from "../components/AuthorizedOnly/AuthorizedOnly";
import { Outcome } from "./Outcome/Outcome";
import TaskSolvedTaskProvider from "../components/Context/TaskContext";
import { ROUTES } from "../constants/Routes";
import { Register } from "./Register/Register";
import Welcome from "./Welcome/Welcome";
import { ResetPasswordPage } from "./ResetPassword/ResetPassword";
import { CreateNewPassword } from "./CreateNewPassword/CreateNewPassword";
import { Dashboard } from "./Dashboard/Dashboard";
import { CreatedNewPasswordSuccess } from "./CreatedNewPasswordSuccess/CreatedNewPasswordSuccess";

export const RoutesLogic = () => {
  return (
    <TaskSolvedTaskProvider>
      <Routes>
        <Route path={ROUTES.welcome} element={<Welcome />} />
        <Route path={ROUTES.login} element={<Login />} />
        <Route path={ROUTES.resetPassword} element={<ResetPasswordPage />} />
        <Route
          path={`${ROUTES.createNewPassword}/:id`}
          element={<CreateNewPassword />}
        />
        <Route path={ROUTES.newPasswordSuccess} element={<CreatedNewPasswordSuccess />} />
        <Route path={ROUTES.register} element={<Register />} />

        <Route
          path={ROUTES.dashboard}
          element={
            <AuthorizedOnly>
              <Dashboard />
            </AuthorizedOnly>
          }
        />

        <Route
          path={ROUTES.mainPage}
          element={
            <AuthorizedOnly>
              <MainPage />
            </AuthorizedOnly>
          }
        />
        <Route
          path={ROUTES.outcome}
          element={
            <AuthorizedOnly>
              <Outcome />
            </AuthorizedOnly>
          }
        />
      </Routes>
    </TaskSolvedTaskProvider>
  );
};
