export const CrossWithBackgroundIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M18.3332 9.99996C18.3332 14.602 14.6019 18.3333 9.99984 18.3333C5.39775 18.3333 1.6665 14.602 1.6665 9.99996C1.6665 5.39788 5.39775 1.66663 9.99984 1.66663C14.6019 1.66663 18.3332 5.39788 18.3332 9.99996Z"
        fill="#EF4444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5348 7.6433L12.3565 6.46497L9.99984 8.82163L7.64318 6.46497L6.46484 7.6433L8.82151 9.99997L6.46484 12.3566L7.64318 13.535L9.99984 11.1783L12.3565 13.535L13.5348 12.3566L11.1782 9.99997L13.5348 7.6433Z"
        fill="#EF4444"
      />
    </svg>
  );
};
