import * as yup from "yup";
export const schema = yup.object().shape({
  task: yup
    .string()
    .required("Treść zadania musi zostać podana")
    .min(5, "Treść zadania musi mieć minimum 5 znaki")
    .max(2000, "Treść zadania może mieć maksymalnie 2000 znaków"),
  level: yup.string().required("Stopień nauczania musi zostać podany"),
  teachingDirection: yup
    .string()
    .min(3, "Kierunek nauczania musi mieć minimum 3 znaki")
    .max(100, "Kierunek nauczania może mieć maksymalnie 100 znaków"),
  specialization: yup
    .string()
    .required("Przedmiot musi zostać podany")
    .min(5, "Przedmiot musi mieć minimum 5 znaki")
    .max(50, "Przedmiot może mieć maksymalnie 2000 znaków"),
  wordsLevel: yup.string().required("Dobór słownictwa musi zostać podany"),
  grade: yup
    .number()
    .test("gradeAboveZero", "Ocena musi zostać podana", (grade) => {
      if (grade === 0) return false;
      return true;
    }),
  minWords: yup
    .number()
    .required("Minimalna ilość znaków musi zostać podana")
    .min(10, "Minimalna ilość znaków to 10")
    .max(500, "Ilość znaków może mieć maksymalnie 10000 znaków"),
});
