import { FolderType } from "../../types/Folder";

export const folderArrayMock: FolderType[] = [
  {
    content: "Język Polski",
    projects: [],
    id: "FGXDFGFGHSFGDH",
  },
  {
    content: "Matematyka",
    projects: [],
    id: "FGXDFGFGHSFG456DH",
  },
  {
    content: "Prace Łysego",
    projects: [],
    id: "FGXDFGFGHSDFSFGDH",
  },
];
