import { FunctionComponent, useState, useEffect } from "react";
import { Modal } from "../Modal/Modal";
import { DeleteOptions } from "./DeleteOptions/DeleteOptions";
import { FolderDeleteOptions } from "../../constants/FolderDeleteOptions";
import { DeleteOnlyFolder } from "./DeleteOnlyFolder/DeleteOnlyFolder";
import { DeleteFolderWithProjects } from "./DeleteFolderWithProjects/DeleteFolderWithProjects";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  body: HTMLBodyElement | null;
  content: string;
  checkIsFunctionDeleteFolderExist: () => void;
}

export const DeleteFolderModal: FunctionComponent<Props> = ({
  isOpen,
  setIsOpen,
  body,
  content,
  checkIsFunctionDeleteFolderExist,
}) => {
  const [currentPage, setCurrentPage] = useState(
    FolderDeleteOptions.chooseOption
  );

  const closeModal = () => {
    if (body) body.style.overflow = "auto";
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentPage(FolderDeleteOptions.chooseOption);
    }
  }, [isOpen]);

  const pages = [
    {
      name: FolderDeleteOptions.chooseOption,
      component: (
        <DeleteOptions
          closeModal={closeModal}
          content={content}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      name: FolderDeleteOptions.deleteFolderAndProjects,
      component: (
        <DeleteFolderWithProjects
          closeModal={closeModal}
          checkIsFunctionDeleteFolderExist={checkIsFunctionDeleteFolderExist}
        />
      ),
    },
    {
      name: FolderDeleteOptions.deleteOnlyFolder,
      component: (
        <DeleteOnlyFolder
          closeModal={closeModal}
          content={content}
          checkIsFunctionDeleteFolderExist={checkIsFunctionDeleteFolderExist}
        />
      ),
    },
  ];

  return (
    <Modal isOpen={isOpen}>
      {pages.find((page) => page.name === currentPage)?.component!}
    </Modal>
  );
};
