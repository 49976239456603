import { useState, useRef, useEffect } from "react";
import { FolderIcon } from "../Icons/Folder";
import { FunctionComponent } from "react";
import { EditIcon } from "../Icons/Edit";
import { TrashIcon } from "../Icons/Trash";
import { PrimaryFolderName } from "../../constants/PrimaryFolderName";
import { CrossIcon } from "../Icons/Cross";
import { KeyboardEvent } from "../AddNewFolder/AddNewFolder";
import { ListOfProjectsType } from "../../types/ListOfPojects";
import { CheckIcon } from "../Icons/Check";
import { blockReturnAfterFirstPreview } from "../../api";
import { useAppDispatch } from "../../store";
import { updateFieldAfterApiCall } from "../../store/slices/projectsSlice";

interface Props {
  content: string;
  projects: string[];
  id: string;
  isActive: boolean;
  setFilterId: (id: string) => void;
  changeFolderTitle?: (title: string) => void;
  setProjectDetails: React.Dispatch<
    React.SetStateAction<ListOfProjectsType | null>
  >;
  setDeleteFolderId?: React.Dispatch<React.SetStateAction<string>>;
  deleteFolderId?: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setContentFolderDelete?: React.Dispatch<React.SetStateAction<string>>;
  isFirstLookAtProjectWithId: { id: number; isUserCanReturnProject: boolean };
}

const body = document.querySelector("body");

export const Folder: FunctionComponent<Props> = ({
  content,
  projects,
  id,
  isActive,
  setFilterId,
  changeFolderTitle,
  setProjectDetails,
  setDeleteFolderId,
  deleteFolderId,
  setIsOpen,
  setContentFolderDelete,
  isFirstLookAtProjectWithId,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedTitle, setEditedTitle] = useState(content);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const openModal = () => {
    if (body) body.style.overflow = "hidden";
    if (!setContentFolderDelete || !setDeleteFolderId || !setIsOpen) return;
    setDeleteFolderId(id);
    setContentFolderDelete(content);
    setIsOpen(true);
  };

  const checkIsFunctionChangeTitleExist = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditedTitle(e.target.value);
  };

  const acceptChangeTitle = () => {
    if (changeFolderTitle) {
      changeFolderTitle(editedTitle);
      setIsEditMode(false);
    }
  };

  const cancelChangeTitle = () => {
    setEditedTitle(content);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (inputRef.current === null) return;
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        acceptChangeTitle();
      } else if (e.key === "Escape") {
        cancelChangeTitle();
      }
    };

    inputRef.current.addEventListener("keydown", handler);

    return () => {
      if (inputRef.current === null) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputRef.current.removeEventListener("keydown", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, inputRef.current, editedTitle]);

  useEffect(() => {
    setIsEditMode(deleteFolderId === id);
  }, [deleteFolderId, id]);

  const blockReturning = async () => {
    if (isFirstLookAtProjectWithId.isUserCanReturnProject) {
      try {
        const data = await blockReturnAfterFirstPreview(
          isFirstLookAtProjectWithId.id
        );
        dispatch(
          updateFieldAfterApiCall({
            id: data.data.data.id,
            attributes: {
              projectTitle: data.data.data.attributes.projectTitle,
              subject: data.data.data.attributes.subject,
              createdAt: data.data.data.attributes.createdAt,
              response: data.data.data.attributes.response,
              isUserCanReturnProject:
                data.data.data.attributes.isUserCanReturnProject,
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }

  const isFolderIsBase =
    PrimaryFolderName.all === id || PrimaryFolderName.edited === id;

  return (
    <div
      className={`flex justify-between items-center p-1 rounded-md cursor-pointer font-satoshi font-normal text-md leading-sm tracking-sm w-[188px] h-[26px] mb-5 group/item focus:outline-2 focus:outline-secondary ${
        isActive ? "bg-secondary" : "bg-white"
      }`}
      onClick={() => {
        blockReturning()
        setFilterId(id);
        setProjectDetails(null);
      }}
      onDoubleClick={() => setIsEditMode(true)}
      tabIndex={0}
    >
      <div className="flex gap-2 items-center text-black-10">
        <FolderIcon className="fill-black-30 w-4 h-4" />
        {isEditMode ? (
          <input
            type="text"
            value={editedTitle}
            className="w-full bg-transparent outline-none"
            onChange={checkIsFunctionChangeTitleExist}
            ref={inputRef}
            autoFocus
          />
        ) : (
          <span className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
            {content.length > 16 ? `${content.slice(0, 16)}...` : content}
          </span>
        )}
      </div>
      <span
        className={`font-satoshi text-sm leading-sm tracking-sm ${
          isFolderIsBase ? "" : "group-hover/item:hidden"
        } ${isActive ? "text-white" : "text-black-70"}`}
      >
        {projects.length}
      </span>
      <div
        className={`hidden gap-[10px] ${
          isFolderIsBase ? "" : "group-hover/item:flex"
        }`}
        onClick={() => setFilterId(id)}
        onDoubleClick={() => setIsEditMode(true)}
      >
        {isEditMode ? (
          <>
            <button onClick={cancelChangeTitle}>
              <CrossIcon className="w-[10.5px] h-[10.5px] fill-black-70" />
            </button>
            <button onClick={acceptChangeTitle}>
              <CheckIcon className="w-[11.62px] h-[9px] fill-black-70" />
            </button>
          </>
        ) : (
          <>
            <button onClick={() => setIsEditMode(true)}>
              <EditIcon className="w-[12px] h-[12px] fill-black-70" />
            </button>
            <button onClick={openModal}>
              <TrashIcon className="w-[12px] h-[12px] fill-black-70" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
