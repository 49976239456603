import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSolveTaskContext } from "../Context/TaskContext";
import { ThumbsUpIcon } from "../Icons/ThumbsUp";
import { WarningSharpAnglesIcon } from "../Icons/WarningSharpAgnles";
import { Opinion, ReportError } from "../../types/Opinion";
import { postOpinion, postReportErrors } from "../../api";

interface Props {
  setAlertContent: Dispatch<SetStateAction<string>>;
  closeReviews: boolean;
  isOpinionExpressed: boolean;
  setCloseReviews: Dispatch<SetStateAction<boolean>>;
  setIsOpinionExpressed: Dispatch<SetStateAction<boolean>>;
}

export const Feedback: React.FC<Props> = ({
  setAlertContent,
  closeReviews,
  setCloseReviews,
  isOpinionExpressed,
  setIsOpinionExpressed,
}) => {
  const [commentValue, setCommentValue] = useState("");
  const [commentValidationError, setCommentValidationError] = useState(false);
  const [isOpenserOpinion, setIsOpenserOpinion] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const { resetErrorState, solvedTask } = useSolveTaskContext();

  const sendOpinion = async (opinion: Opinion) => {
    try {
      await postOpinion(opinion);
    } catch (error) {
      console.log(error);
    }
  };

  const sendReportErrors = async (opinion: ReportError) => {
    try {
      await postReportErrors(opinion);
    } catch (error) {
      console.log(error);
    }
  };

  const handleComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(e.target.value);
  };

  const checkCommentIsEmpty = () => {
    if (!commentValue) return true;
  };

  const sendUserOpinion = () => {
    if (checkCommentIsEmpty()) return setCommentValidationError(true);
    setCommentValidationError(false);
    sendOpinion({
      isPositive: false,
      comment: commentValue,
      ai_request: solvedTask.id ? solvedTask.id.toString() : "",
    });
    setAlertContent("Dziękujemy za twoją opinię");
    endReviews();
  };

  const sendUserReport = () => {
    if (checkCommentIsEmpty()) return setCommentValidationError(true);
    setCommentValidationError(false);
    setAlertContent("Dziękujemy za zgłoszenie");
    sendReportErrors({
      comment: commentValue,
      ai_request: solvedTask.id ? solvedTask.id.toString() : "",
    });
    endReviews();
  };

  const handleUserThumbsUp = async () => {
    setAlertContent("Dziękujemy za twoją opinię");
    console.log("solvedTask id", solvedTask.id);
    await sendOpinion({
      isPositive: true,
      comment: "",
      ai_request: String(solvedTask.id!),
    });
    endReviews();
  };

  const handleUserThumbsDown = () => {
    setIsOpenserOpinion(true);
    setIsReport(false);
  };

  const handleReportOpen = () => {
    setIsReport(true);
    setIsOpenserOpinion(true);
  };

  const endReviews = () => {
    resetErrorState();
    setIsOpenserOpinion(false);
    setIsReport(false);
    setCloseReviews(true);
    setIsOpinionExpressed(false);
  };

  useEffect(() => {
    if (solvedTask.isError) {
      setAlertContent(
        "Coś poszło nie tak, spróbuj ponownie przez kliknięcie “Generuj ponownie”"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solvedTask.isError]);

  return (
    <>
      <div
        className={`border-y border-black-50 min-h-12 py-3 mt-8 ${
          isOpinionExpressed && "border-errorPopupText hover:border-hover-error"
        } ${closeReviews && "opacity-25 pointer-events-none"}`}
      >
        <span className="font-inter font-normal text-lg leading-md tracking-sm text-black-75 flex items-center justify-between">
          Prosimy o ocenienie pracy:
          <span className="flex gap-3">
            <button onClick={handleUserThumbsUp}>
              <ThumbsUpIcon className="fill-black-50 cursor-pointer" />
            </button>
            <button onClick={handleUserThumbsDown}>
              <ThumbsUpIcon className="fill-black-50 rotate-180 cursor-pointer" />
            </button>
            <div className="bg-black-25 h-4 w-[1px]"></div>
            <button onClick={handleReportOpen}>
              <WarningSharpAnglesIcon className="fill-black-50 cursor-pointer" />
            </button>
          </span>
        </span>
        {isOpenserOpinion && (
          <div className="flex flex-col mt-6">
            <textarea
              onChange={handleComments}
              value={commentValue}
              placeholder={`${
                isReport ? "Opisz problem" : "Dlaczego praca ci się nie podoba?"
              }`}
              className={`w-full min-h-[128px] rounded-md px-2 py-3 border border-black-50
               font-inter font-normal text-lg leading-md tracking-sm text-black-100 ${
                 commentValidationError && "border-errorPopupText hover:border-hover-error"
               }`}
            ></textarea>
            {commentValidationError && (
              <p className="font-inter font-normal text-sm leading-sm tracking-sm text-errorPopupText mt-1">
                {isReport ? "Prosimy o opisanie problemu" : "Prosimy o opinie"}
              </p>
            )}
            <button
              className="w-full rounded-md px-2 py-3 font-inter border border-gray-300 font-semibold text-xl leading-md text-black-75 self-center mt-6"
              onClick={isReport ? sendUserReport : sendUserOpinion}
            >
              {isReport ? "Zgłoś problem" : "Prześlij opinię"}
            </button>
          </div>
        )}
      </div>
      {isOpinionExpressed && (
        <p className="font-inter font-normal text-lg leading-md tracking-sm text-errorPopupText">
          Prosimy o ocenę przed wygenerowaniem następnej pracy
        </p>
      )}
    </>
  );
};
