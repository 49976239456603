import { createContext, ReactNode, useContext, useState } from "react";
import { getSolvedTask, solveTask } from "../../api";
import { TaskForm } from "../../types/TaskForm";
import { initialValues } from "../MainPage/FormTask";

type SolvedTaskContextProps = {
  children: ReactNode;
};

type Task = {
  message: string;
  cost: number;
  isLoading: boolean;
  isError: boolean;
  id: number | null;
};

type TaskContextType = {
  getResolveTask: (task: TaskForm) => void;
  generateAgain: () => void;
  resetErrorState: () => void;
  resetSolvedTaskState: () => void;
  solvedTask: Task;
  storedContentTask: TaskForm
};

const SolvedTask = createContext({} as TaskContextType);

export const useSolveTaskContext = () => useContext(SolvedTask);

const TaskSolvedTaskProvider = ({ children }: SolvedTaskContextProps) => {
  const [storedContentTask, setStoredContentTask] =
    useState<TaskForm>(initialValues);
  const [solvedTask, setSolvedTask] = useState<Task>({
    message: "",
    cost: 0,
    isLoading: false,
    isError: false,
    id: null
  });

  const resetSolvedTaskState = () => {
    setSolvedTask({
      message: "",
      cost: 0,
      isLoading: false,
      isError: false,
      id: null
    });
  };

  const generateAgain = () => getResolveTask(storedContentTask);

  const resetErrorState = () => {
    setSolvedTask((prevState) => {
      return {
        ...prevState,
        isError: false
      };
    });
  };

  const getResolveTask = async (task: TaskForm) => {
    setStoredContentTask(task);
    setSolvedTask({
      message: "",
      cost: 0,
      isLoading: true,
      isError: false,
      id: null
    });
    try {
      const res = await solveTask(task);
      const taskId = res.data.requestId;

      if (!taskId) {
        throw new Error("cannot get task id");
      }

      let pollResult: any = undefined;

      const pollingInterval = setInterval(async () => {
        pollResult = await getSolvedTask(taskId);
        if (pollResult.data.message) {

          clearInterval(pollingInterval);
          if (res.status === 200) {
            setSolvedTask({
              message: pollResult.data.message,
              cost: pollResult.data.cost,
              isLoading: false,
              isError: false,
              id: taskId
            });
          }
        }

      }, 5000);


    } catch (error) {
      setSolvedTask({
        message: "",
        cost: 0,
        isLoading: false,
        isError: true,
        id: null
      });
      console.log(error);
    }
  };

  return (
    <SolvedTask.Provider
      value={{
        solvedTask,
        getResolveTask,
        generateAgain,
        resetErrorState,
        resetSolvedTaskState,
        storedContentTask
      }}
    >
      {children}
    </SolvedTask.Provider>
  );
};

export default TaskSolvedTaskProvider;
