export const ArrowWithShadowIcon = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_643_9422)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M159.349 53.738C162.149 56.3182 162.404 60.8134 159.913 63.7245L89.8013 145.678C87.2977 148.604 82.9991 148.79 80.2759 146.091L39.7048 105.868C37.1746 103.359 36.9324 99.1792 39.1032 96.3217C41.4985 93.1685 45.9591 92.7345 48.8098 95.4347L80.2876 125.252C83.0231 127.844 87.2347 127.612 89.6993 124.735L150.006 54.3189C152.445 51.4694 156.607 51.2111 159.349 53.738Z"
          fill="#00EDD9"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_643_9422"
          x="12.627"
          y="32"
          width="174"
          height="146"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.929412 0 0 0 0 0.85098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_643_9422"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_643_9422"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
