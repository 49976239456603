import { FunctionComponent } from "react";

type Props = {
  when: string;
};

export const LineWhen: FunctionComponent<Props> = ({ when }) => {
  return (
    <div className="flex gap-2 items-center mb-4 w-[calc(100%_-_65px)]">
      <p className="whitespace-nowrap font-normal leading-lg tracking-sm text-black-30 font-satoshi">{when}</p>
      <div className="w-full h-[1px] bg-black-90"></div>
    </div>
  );
};
