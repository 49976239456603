export enum EducationLevels {
  primarySchool = "Szkoła podstawowa",
  highSchool = "Technikum / Liceum",
  studies = "Studia",
}

export enum WordsLevel {
  easy = "Banalne",
  medium = "Proste",
  specialized = "Specialistyczne",
}
