import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { userDetailsRegisterSchema } from "../../validation/registerSchema";
import { RegisterUserInterface } from "../../types/RegisterForm";
import { LogoIcon } from "../../components/Icons/Logo";
import { Input } from "../../components/Input/Input";
import { ValidationError } from "../../components/ValidationError/ValidationError";
import { createFolderForNewProjects, register } from "../../api";
import { setToken } from "../../helpers/auth";
import { ROUTES } from "../../constants/Routes";

export const Register = () => {
  const navigate = useNavigate();
  const onSubmit = async (userRegisterData: RegisterUserInterface) => {
    try {
      const { data } = await register({
        email: userRegisterData.email,
        password: userRegisterData.password,
        username: userRegisterData.email,
      });
      setToken(data);
      await createFolderForNewProjects(data.user.id)
      navigate(ROUTES.mainPage);
    } catch (error) {
      console.log(error);
    }
  };

  const { handleChange, values, handleSubmit, touched, errors } =
    useFormik<RegisterUserInterface>({
      initialValues: {
        email: "",
        password: "",
        isRegulationsChecked: false,
      },
      validationSchema: userDetailsRegisterSchema,
      onSubmit,
    });

  const isAnyErrorExist =
    (errors.email && touched.email) ||
    (errors.isRegulationsChecked && touched.isRegulationsChecked) ||
    (errors.password && touched.password);

  const isRegulationsErros =
    errors.isRegulationsChecked && touched.isRegulationsChecked;

  return (
    <div className="flex gap-10 items-center justify-center h-screen">
      <div className="fixed top-[18px] left-4 w-[180px] h-8 bg-button-default"></div>
      <LogoIcon />
      <div
        className={`flex flex-col max-w-[448px] min-h-[405px] p-8 shadow-cardDefault rounded-[20px] ${
          isAnyErrorExist && "shadow-cardError"
        }`}
      >
        <p className="mb-6 font-black font-satoshi text-2xl leading-2xl tracking-md text-black-10">
          Rejestracja
        </p>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="flex flex-col"
        >
          <Input
            labelContent="Adres e-mail"
            type="email"
            placeholder="Podaj swój adres e-mail"
            name="email"
            value={values.email}
            onHandleUserForm={handleChange}
            isTouched={touched.email}
            validationError={errors.email}
          />
          <Input
            labelContent="Hasło"
            type="password"
            placeholder="Wprowadź hasło"
            name="password"
            value={values.password}
            onHandleUserForm={handleChange}
            isTouched={touched.password}
            validationError={errors.password}
          />
          <div className="my-6">
            <div className="flex items-start">
              <input
                type="checkbox"
                id="regulation"
                name="isRegulationsChecked"
                onChange={handleChange}
                className={`w-5 h-5 mt-1.5 outline-none border-black-70 accent-main border focus:border-button-focus hover:border-2 transition-[border-width] cursor-pointer mr-2 ${
                  isRegulationsErros && "border-error hover:border-hover-error"
                }`}
              />

              <div className="max-w-[356px] font-satoshi font-normal text-sm tracking-sm leading-sm text-black-10">
                <label htmlFor="regulation">
                  Przeczytałem(-am) i zgadzam się z
                  <Link to="/welcome" className="text-main underline mx-1">
                    Regulaminem
                  </Link>
                  oraz
                  <Link to="/welcome" className="text-main underline mx-1">
                    Polityką prywatności
                  </Link>
                  Napiszemy.To
                </label>
              </div>
            </div>
            {isRegulationsErros && (
              <ValidationError error={errors.isRegulationsChecked!} />
            )}
          </div>

          <div className="flex flex-col gap-3">
            <button
              className="w-full rounded-[20px] bg-button-default hover:bg-button-hover transition-[background-color] font-satoshi font-medium text-md leading-md text-white self-baseline py-2"
              type="submit"
            >
              Zarejestruj się
            </button>
            <div className="bg-black-90 w-full h-[1px]"></div>
            <p className="font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30">
              Masz już konto?
              <Link to="/login" className="text-main underline ml-1">
                Zaloguj się
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
