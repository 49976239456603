import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const TrashIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-original",
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.5 4H21V3C21 1.34581 19.6542 0 18 0H14C12.3458 0 11 1.34581 11 3V4H5.5C4.1215 4 3 5.1215 3 6.5V10C3 10.5522 3.44775 11 4 11H4.5465L5.41044 29.1427C5.48675 30.7449 6.803 32 8.407 32H23.593C25.1971 32 26.5133 30.7449 26.5896 29.1427L27.4535 11H28C28.5522 11 29 10.5522 29 10V6.5C29 5.1215 27.8785 4 26.5 4ZM13 3C13 2.44863 13.4486 2 14 2H18C18.5514 2 19 2.44863 19 3V4H13V3ZM5 6.5C5 6.22431 5.22431 6 5.5 6H26.5C26.7757 6 27 6.22431 27 6.5V9C26.6918 9 6.27706 9 5 9V6.5ZM24.5918 29.0476C24.5664 29.5816 24.1276 30 23.593 30H8.407C7.87231 30 7.43356 29.5816 7.40819 29.0476L6.54875 11H25.4513L24.5918 29.0476Z" />
      <path d="M16 28C16.5523 28 17 27.5522 17 27V14C17 13.4477 16.5523 13 16 13C15.4477 13 15 13.4477 15 14V27C15 27.5522 15.4477 28 16 28Z" />
      <path d="M21 28C21.5523 28 22 27.5522 22 27V14C22 13.4477 21.5523 13 21 13C20.4477 13 20 13.4477 20 14V27C20 27.5522 20.4477 28 21 28Z" />
      <path d="M11 28C11.5523 28 12 27.5522 12 27V14C12 13.4477 11.5523 13 11 13C10.4477 13 10 13.4477 10 14V27C10 27.5522 10.4477 28 11 28Z" />
    </svg>
  );
};
