import React from "react";

interface Props {
  error: string;
}

export const ValidationError: React.FC<Props> = ({ error }) => {
  return (
    <p className="text-error font-satoshi font-normal text-sm leading-sm tracking-sm mt-0.5">
      {error}
    </p>
  );
};