import { FunctionComponent } from "react";
import { CrossIcon } from "../../Icons/Cross";
import { FolderDeleteOptions } from "../../../constants/FolderDeleteOptions";

interface Props {
  closeModal: () => void;
  content: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<FolderDeleteOptions>>;
}

export const DeleteOptions: FunctionComponent<Props> = ({
  closeModal,
  content,
  setCurrentPage,
}) => {
  return (
    <div className="flex flex-col">
      <button className="p-[16.75px] self-end" onClick={closeModal}>
        <CrossIcon />
      </button>
      <p className="px-8 mb-6 font-satoshi font-bold text-xl text-black-10">
        Usuwanie folderu: Co chcesz zrobić z jego zawartością?
      </p>
      <p className="px-8 font-satoshi font-normal text-md leading-md tracking-sm text-black-20">
        Próbujesz usunąć folder <b>“{content}”.</b> Chcesz usunąć sam folder czy
        również prace zawarte w nim?
      </p>
      <div className="flex gap-3 self-end pb-8 pr-8 pt-6">
        <button
          className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 border border-black-70 rounded-[20px]"
          onClick={() =>
            setCurrentPage(FolderDeleteOptions.deleteFolderAndProjects)
          }
        >
          Usuwam folder oraz jego zawartość
        </button>
        <button
          className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-white bg-button-default
           rounded-[20px]"
          onClick={() => setCurrentPage(FolderDeleteOptions.deleteOnlyFolder)}
        >
          Usuwam sam folder
        </button>
      </div>
    </div>
  );
};
