import { useState, useEffect, forwardRef, Ref, HTMLAttributes } from "react";
import { ArrowDownIcon } from "../Icons/ArrowDown";
import { DownloadIcon } from "../Icons/Download";
import { FolderIcon } from "../Icons/Folder";
import { MoveToIcon } from "../Icons/MoveTo";
import { TrashIcon } from "../Icons/Trash";
import { EditIcon } from "../Icons/Edit";
import { SearchIcon } from "../Icons/Search";
import { CopyToClipboardIcon } from "../Icons/CopyToClipboard";
import { DropDownMenu } from "../DropDownMenu/DropDownMenu";
import { FileIcon } from "../Icons/File";
import { FolderType } from "../../types/Folder";
import { Modal } from "../Modal/Modal";
import { CrossIcon } from "../Icons/Cross";
import { ReturnIcon } from "../Icons/Return";

interface Props extends HTMLAttributes<HTMLDivElement> {
  selectedFolderName: string;
  clickedProjectId: number;
  folders: FolderType[];
  deleteSelectedProject: () => void;
  openInputWithTitle: () => void;
  handleSearchProjects: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchProjects: string;
  isUserCanReturnProject: boolean;
}

interface FolderInterface {
  icon: JSX.Element;
  text: string;
  id: string;
}

const body = document.querySelector("body");

const getRandomId = () => Math.floor(Math.random() * 1000).toString(); // TODO: remove this function

const downloadOptions: FolderInterface[] = [
  {
    icon: <FileIcon className="fill-black-70" />,
    text: "Pobierz jako PDF",
    id: getRandomId(),
  },
  {
    icon: <FileIcon className="fill-black-70" />,
    text: "Pobierz jako DOCX",
    id: getRandomId(),
  },
  {
    icon: <CopyToClipboardIcon className="fill-black-70" />,
    text: "Pobierz jako PDF",
    id: getRandomId(),
  },
];

export const DashboardHeader = forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const {
      selectedFolderName,
      clickedProjectId,
      deleteSelectedProject,
      folders,
      openInputWithTitle,
      searchProjects,
      handleSearchProjects,
      isUserCanReturnProject,
    } = props;
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const [showFolderChangeOptions, setShowFolderChangeOptions] =
      useState(false);
    const [openOnlyOneDropDown, setOpenOnlyOneDropDown] = useState({
      download: false,
      moveFolder: false,
    });
    const [isOpen, setIsOpen] = useState(false);
    const [folderOptions, setFolderOptions] = useState([
      {
        icon: <FolderIcon className="fill-black-70 w-4 h-4" />,
        text: "Wszystkie prace",
        id: getRandomId(),
      },
      {
        icon: <FolderIcon className="fill-black-70 w-4 h-4" />,
        text: "Zedytowane prace",
        id: getRandomId(),
      },
    ]);

    const closeModal = () => {
      if (body) body.style.overflow = "auto";
      setIsOpen(false);
    };
    const openModal = () => {
      if (body) body.style.overflow = "hidden";
      setIsOpen(true);
    };

    const deleteProject = () => {
      deleteSelectedProject();
      closeModal();
    };

    const handleShowDownloadOptions = () => {
      setShowDownloadOptions((prevState) => !prevState);
      setShowFolderChangeOptions(false);
      setOpenOnlyOneDropDown({
        download: true,
        moveFolder: false,
      });
    };

    const handleShowFolderChangeOptions = () => {
      setShowFolderChangeOptions((prevState) => !prevState);
      setShowDownloadOptions(false);
      setOpenOnlyOneDropDown({
        download: false,
        moveFolder: true,
      });
    };

    const closeAllDropDown = () => {
      setShowFolderChangeOptions(false);
      setShowDownloadOptions(false);
      setOpenOnlyOneDropDown({
        download: false,
        moveFolder: false,
      });
    };

    const isNotSelectedProjectStyle = !clickedProjectId
      ? "opacity-50 pointer-events-none"
      : "";

    const findNewFolder = (
      folders: FolderType[],
      folderOptions: FolderInterface[]
    ) => {
      const newFolder = folders.filter((folder) => {
        const isFolderInFolderOptions = folderOptions.find(
          (folderOption) => folderOption.text === folder.content
        );
        return !isFolderInFolderOptions;
      });
      return newFolder;
    };

    useEffect(() => {
      const newFolder = findNewFolder(folders, folderOptions);
      if (newFolder.length > 0) {
        newFolder.forEach((folder) => {
          setFolderOptions((prevState) => [
            ...prevState,
            {
              icon: <FolderIcon className="fill-black-70 w-4 h-4" />,
              text: folder.content,
              id: getRandomId(),
            },
          ]);
        });
      }
    }, [folderOptions, folders]);

    return (
      <>
        <header
          className="flex h-16 w-[calc(100%_-_214px)] justify-between p-4 border-b border-black-90 fixed z-10 bg-white"
          ref={ref}
        >
          <div className="flex gap-3">
            <FolderIcon className="w-6 h-6 fill-black-30" />
            <span className="font-satoshi font-medium text-lg leading-lg tracking-sm text-black-10">
              {selectedFolderName?.length > 20
                ? `${selectedFolderName.slice(0, 20)}...`
                : selectedFolderName}
            </span>
          </div>
          <div className="relative">
            <SearchIcon className="absolute fill-[#A5ABC0] left-[14px] top-2/4 -translate-y-2/4 cursor-pointer" />
            <input
              value={searchProjects}
              type="text"
              placeholder="Wyszukaj..."
              className={`font-satoshi font-normal text-sm leading-sm tracking-sm text-black-30 w-[224px] h-8 py-1 px-3 border border-black-90 rounded-[20px] outline-none focus:border-secondary active:border-2 focus:border-2 active:border-black-90 pl-[38px]`}
              onChange={handleSearchProjects}
            />
          </div>
          <div className="flex gap-8 items-center font-medium">
            <button
              className={`cursor-pointer ${isNotSelectedProjectStyle}`}
              onClick={openModal}
            >
              <TrashIcon className="w-4 h-4 fill-black-30" />
            </button>
            <div className="w-[1px] h-[32px] bg-black-90"></div>
            {isUserCanReturnProject && (
              <>
              <button className="cursor-pointer">
                <ReturnIcon />
              </button>
                <div className="w-[1px] h-[32px] bg-black-90"></div>
              </>
            )}
            <div className="relative">
              <button
                className={`flex gap-2 items-center p-[4.5px] rounded ${isNotSelectedProjectStyle} ${
                  showDownloadOptions && "bg-secondary"
                }`}
                onClick={handleShowDownloadOptions}
              >
                <DownloadIcon className="w-4 h-4 fill-black-30" />
                <ArrowDownIcon className="w-2 h-2 fill-black-30" />
              </button>
              {showDownloadOptions && openOnlyOneDropDown.download && (
                <DropDownMenu
                  options={downloadOptions}
                  closeAllDropDown={closeAllDropDown}
                />
              )}
            </div>
            <div className="relative">
              <button
                className={`flex gap-2 items-center p-[4.5px] rounded ${isNotSelectedProjectStyle} ${
                  showFolderChangeOptions && "bg-secondary"
                }`}
                onClick={handleShowFolderChangeOptions}
              >
                <MoveToIcon className="w-4 h-4 fill-black-30" />
                <ArrowDownIcon className="w-2 h-2 fill-black-30" />
              </button>
              {showFolderChangeOptions && openOnlyOneDropDown.moveFolder && (
                <DropDownMenu
                  options={folderOptions}
                  closeAllDropDown={closeAllDropDown}
                />
              )}
            </div>
            <button
              className={`${isNotSelectedProjectStyle}`}
              onClick={openInputWithTitle}
            >
              <EditIcon className="w-4 h-4 fill-black-30" />
            </button>
            <button
              className={`font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 border border-buttonBorder rounded-[20px] py-[7px] px-6 ${isNotSelectedProjectStyle}`}
            >
              Edytuj pracę
            </button>
          </div>
        </header>
        <Modal isOpen={isOpen}>
          <div className="flex flex-col">
            <button className="p-[16.75px] self-end" onClick={closeModal}>
              <CrossIcon />
            </button>
            <p className="px-8 mt-[20.75px] mb-6 font-satoshi font-bold text-xl">
              Ostrzeżenie: Usuwanie pracy
            </p>
            <p className="px-8 font-satoshi font-normal text-md leading-md tracking-sm">
              Usunięcie pracy <b>“Nazwa pracy”</b> jest nieodwracalne.
              <b>Akcja ta nie wpłynie na limit prac przypisany do konta.</b> Czy
              na pewno chcesz to zrobić? Upewnij się, że nie potrzebujesz już
              tej pracy przed podjęciem decyzji.
            </p>
            <div className="flex gap-3 self-end pb-[46px] pr-8 pt-6">
              <button
                className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-black-10 border border-black-70 rounded-[20px]"
                onClick={closeModal}
              >
                Anuluj
              </button>
              <button
                className="py-[7px] px-6 font-satoshi font-medium text-sm leading-sm tracking-sm text-white bg-error rounded-[20px]"
                onClick={deleteProject}
              >
                Usuwam pracę
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
