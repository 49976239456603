export const setToken = (token: string) => {
  return sessionStorage.setItem("user", JSON.stringify(token));
};

export const clearToken = () => {
  return sessionStorage.removeItem("user");
};

export const getToken = () => {
  const token = sessionStorage.getItem("user");
  if (token) {
    return JSON.parse(token)?.jwt;
  }
  return undefined;
};

export const getUserEmail = () => {
  const email: any = sessionStorage.getItem("user");
  if (email) {
    return JSON.parse(email).user.email
  }
};
