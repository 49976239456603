import { FormikErrors, FormikValues } from "formik";
import { useState, FunctionComponent, Dispatch, SetStateAction } from "react";
import { ArrowDownIcon } from "../Icons/ArrowDown";

interface Props {
  labelTitle: string;
  validationErrorBorder: false | "" | string | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  values: string[] | number[];
  formikStateName: string;
  stateValue: string | number | null;
  error: string | undefined;
  className?: string;
  setErrorIsRequired?: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
}

export const CustomSelect: FunctionComponent<Props> = ({
  labelTitle,
  validationErrorBorder,
  setFieldValue,
  values,
  formikStateName,
  stateValue,
  error,
  className,
  setErrorIsRequired,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenSelect = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSetState = (value: string) => {
    setFieldValue(formikStateName, value);
    handleOpenSelect();
    if (setErrorIsRequired) {
      setErrorIsRequired(false);
    }
  };

  const placeholderValue = (
    <span>
      {stateValue ? (
        stateValue
      ) : (
        <span className="text-black-50">{placeholder}</span>
      )}
    </span>
  );

  const arrowDirection = isOpen
    ? "rotate-180 transition-transform"
    : "rotate-0 transition-transform";

  return (
    <div className="w-full">
      <p className="font-inter font-normal text-lg leading-md tracking-sm text-black-100">
        {labelTitle}
      </p>
      <div
        tabIndex={0}
        className={`border border-black-50 absolute cursor-pointer rounded-md focus:border-2 focus:border-borderPurple disabled:border-black-25 ${className} ${validationErrorBorder}`}
      >
        <button
          onClick={handleOpenSelect}
          className="h-10 px-2 py-3 flex items-center justify-between w-full"
        >
          {placeholderValue}
          <ArrowDownIcon className={arrowDirection} />
        </button>
        <div
          className={`flex flex-col px-2 max-h-40 overflow-y-scroll bg-white w-full ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          {values.map((value, index) => (
            <div key={index} className="border-t border-black-25">
              <button
                className="py-3 font-inter font-semibold text-lg leading-md tracking-sm text-black-100 text-left w-full"
                onClick={() => handleSetState(value as string)}
              >
                {value}
              </button>
            </div>
          ))}
        </div>
      </div>
      {validationErrorBorder ? (
        <p className="text-error font-inter font-normal text-sm leading-sm tracking-sm mt-1">
          {error}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};
