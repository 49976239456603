import { FunctionComponent } from "react";
import { IconProps } from "../../types/Icons";

export const DownloadIcon: FunctionComponent<IconProps> = ({
  className = "fill-black-30",
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_368_3103)">
        <path
          d="M10.5409 7.69994L8.62473 9.61613V4.875C8.62473 4.52981 8.34492 4.25 7.99973 4.25C7.65455 4.25 7.37473 4.52981 7.37473 4.875V9.61613L5.45855 7.69994C5.21448 7.45584 4.81873 7.45584 4.57467 7.69994C4.33058 7.94403 4.33058 8.33975 4.57467 8.58384C5.22889 9.23797 7.52167 11.5311 7.5617 11.5705C7.80633 11.8112 8.19945 11.8092 8.44167 11.567L11.4248 8.58384C11.6689 8.33975 11.6689 7.94403 11.4248 7.69994C11.1807 7.45584 10.785 7.45584 10.5409 7.69994Z"
        />
        <path
          d="M8 0.5C3.85506 0.5 0.5 3.8545 0.5 8C0.5 12.1449 3.85447 15.5 8 15.5C12.1449 15.5 15.5 12.1455 15.5 8C15.5 3.85506 12.1455 0.5 8 0.5ZM8 14.25C4.55375 14.25 1.75 11.4462 1.75 8C1.75 4.55375 4.55375 1.75 8 1.75C11.4462 1.75 14.25 4.55375 14.25 8C14.25 11.4462 11.4462 14.25 8 14.25Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_368_3103">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
